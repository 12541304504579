import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { resources } from "../resources/index";
import moment from "moment";
import Util from "./Util";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PDF {

    static export = (title, header, dataSet, active_company, page_size, orientation, number_indices, widths) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: page_size,
            pageOrientation: orientation,
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: [
                {
                    //image:'companyLogo',
                },
                {
                    text: `${active_company.name}`,
                    alignment: "center",
                    fontSize: 11,
                    margin: [0, 0, 0, 5]
                },
                {
                    text: `${title.toUpperCase()}`, 
                    style: 'header', 
                    alignment: "center", 
                    fontSize: 11,
                    margin: [0, 0, 0, 15]
                },
                {
                    layout: "headerLineOnly",
                    fontSize: 8,
                    table: {
                        headerRows: 1,
                        widths: widths,
                        body: [
                            [
                                ...header.map((obj, key) => ({
                                    text: obj.toUpperCase(),
                                    bold: true,
                                    fillColor: "#87CEEB",
                                    alignment:number_indices.includes(key) ? "right" : "left"
                                })),
                            ],
                            ...dataSet.map((obj, key) => [...(obj.map((txt, key) => ({text:txt, alignment:number_indices.includes(key) ? "right" : "left"})))]),
                        ],
                    },
                },
            ],
            images: {
                //companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            },
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? "black" : "#aaa";
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                },
            },
        };

        pdfMake
            .createPdf(docDefinition, pdfMake.tableLayouts)
            .download(`${title}.pdf`);
    };

    static exportWithoutHeader = (title, header, dataSet, active_company, page_size, orientation, number_indices, widths) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: page_size,
            pageOrientation: orientation,
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa"
                    },
                ];
            },
            content: [
                {
                    text: `${active_company.name}`,
                    alignment: "center",
                    fontSize: 11,
                    margin: [0, 0, 0, 5]
                },
                {
                    text: `${title.toUpperCase()}`, 
                    style: 'header', 
                    alignment: "center", 
                    fontSize: 11,
                    margin: [0, 0, 0, 15]
                },
                {
                    layout: "headerLineOnly",
                    fontSize: 8,
                    table: {
                        headerRows: 0,
                        widths: widths,
                        body: [
                            ...dataSet.map((obj, key) => [...(obj.map((txt, key) => ({text:txt, alignment:number_indices.includes(key) ? "right" : "left"})))]),
                        ],
                    },
                },
                
            ]
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? "black" : "#aaa";
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                },
            },
        };

        pdfMake
            .createPdf(docDefinition, pdfMake.tableLayouts)
            .download(`${title}.pdf`);
    };

    static exportBarcodes = (
        title,
        header,
        barcodes,
        width,
        height,
        horizontalMargin,
        verticalMargin
    ) => {
        var docDefinition = {
            pageSize: {
                width: width,
                height: height,
            },
            pageMargins: [
                horizontalMargin,
                verticalMargin,
                horizontalMargin,
                verticalMargin,
            ],
            content: barcodes,
        };
        pdfMake.createPdf(docDefinition).open();
    };

    static exportQRCodes = (title, header, qrCodes, width, height, margin) => {
        var docDefinition = {
            pageSize: {
                width: width,
                height: height,
            },
            content: qrCodes,
            pageMargins: [0, margin, 0, 0],
        };
        pdfMake.createPdf(docDefinition).open();
    };

    static exportStockRequestForm = (title, docs, stock_request_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Requesting Unit : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.branch ? doc.branch.name : '',
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 80, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Quantity', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]}
                                ],
                                ...stock_request_line_items.map((stockRequestLineItem) => [
                                    {text: stockRequestLineItem.item.label, alignment: 'left'},
                                    {text: stockRequestLineItem.quantity, alignment: 'right'},
                                    {text: stockRequestLineItem.uom.label, alignment: 'center'}
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportStockTransferForm = (title, docs, stock_transfer_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Transfer from : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${JSON.parse(doc.transfer_from).label} to ${JSON.parse(doc.transfer_to).label}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 80, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Quantity', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...stock_transfer_line_items.map((stockTransferLineItem) => [
                                    stockTransferLineItem.item.label,
                                    {text: stockTransferLineItem.quantity, alignment: 'right'},
                                    {text: stockTransferLineItem.uom.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportStockIssuanceForm = (title, docs, stock_issuance_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Transfer from : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${JSON.parse(doc.transfer_from).label} to ${JSON.parse(doc.transfer_to).label}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 80, 70, 70, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Cancel Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'Issue Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'From location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...stock_issuance_line_items.map((stockIssuanceLineItem) => [
                                    stockIssuanceLineItem.item.label,
                                    {text: stockIssuanceLineItem.cancel_quantity, alignment: 'right'},
                                    {text: stockIssuanceLineItem.quantity, alignment: 'right'},
                                    {text: stockIssuanceLineItem.uom.label, alignment: 'center'},
                                    {text: stockIssuanceLineItem.from_location.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportStockAcceptanceForm = (title, docs, stock_acceptance_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Transfer from : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${doc.transfer_from ? JSON.parse(doc.transfer_from).label : 'External Supplier'} to ${JSON.parse(doc.transfer_to).label}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 80, 70, 70, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Issued Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'Accepted Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'To location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...stock_acceptance_line_items.map((stockAcceptanceLineItem) => [
                                    stockAcceptanceLineItem.item.label,
                                    {text: stockAcceptanceLineItem.issued_quantity, alignment: 'right'},
                                    {text: stockAcceptanceLineItem.quantity, alignment: 'right'},
                                    {text: stockAcceptanceLineItem.uom.label, alignment: 'center'},
                                    {text: stockAcceptanceLineItem.to_location.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportFinishedGoodProducedForm = (title, docs, stock_acceptance_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Finished Good Produced : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${doc.finished_good_item.code} | ${doc.finished_good_item.description}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 80, 70, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Quantity', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'To location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...stock_acceptance_line_items.map((stockAcceptanceLineItem) => [
                                    stockAcceptanceLineItem.item.label,
                                    {text: stockAcceptanceLineItem.quantity, alignment: 'right'},
                                    {text: stockAcceptanceLineItem.uom.label, alignment: 'center'},
                                    {text: stockAcceptanceLineItem.location.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportImportedItemsReceiptForm = (title, docs, stock_acceptance_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Transfer from : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${doc.transfer_from ? JSON.parse(doc.transfer_from).label : 'External Supplier'} to ${JSON.parse(doc.transfer_to).label}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 80, 70, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Accepted Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'To location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...stock_acceptance_line_items.map((stockAcceptanceLineItem) => [
                                    stockAcceptanceLineItem.item.label,
                                    {text: stockAcceptanceLineItem.quantity, alignment: 'right'},
                                    {text: stockAcceptanceLineItem.uom.label, alignment: 'center'},
                                    {text: stockAcceptanceLineItem.to_location.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportBeginningBalanceForm = (title, docs, stock_adjustment_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        image:'companyLogo'
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Transaction Description : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${doc.description}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Profit Cost Center : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.profit_cost_center ? JSON.parse(doc.profit_cost_center).label : '',
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 70, 70, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...stock_adjustment_line_items.map((stockAcceptanceLineItem) => [
                                    stockAcceptanceLineItem.item.label,
                                    {text: Util.formatQuantity(stockAcceptanceLineItem.quantity), alignment: 'right'},
                                    {text: stockAcceptanceLineItem.uom.label, alignment: 'center'},
                                    {text: stockAcceptanceLineItem.location.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportStockAdjustmentForm = (title, docs, stock_adjustment_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Profit Cost Center : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: JSON.parse(doc.profit_cost_center).label,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Transaction Description : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.description,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 60, 80, 70, 60],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Operation', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Quantity', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'Location', style: 'tableHeader', alignment: 'left', margin: [0, 7, 0, 7]}
                                ],
                                ...stock_adjustment_line_items.map((stockAdjustmentLineItem) => [
                                    {text: stockAdjustmentLineItem.item.label, alignment: 'left'},
                                    {text: stockAdjustmentLineItem.increment ? "Increase +" : "Decrease -", alignment: 'left'},
                                    {text: stockAdjustmentLineItem.uom.label, alignment: 'center'},
                                    {text: stockAdjustmentLineItem.quantity, alignment: 'right'},
                                    {text: stockAdjustmentLineItem.location.label, alignment: 'left'}
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

    static exportItemLocationTransferForm = (title, docs, item_location_transfer_line_items, active_company) => {
        var docDefinition = {
            info: {
                title: title,
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            footer: function (currentPage: number, pageCount: number) {
                return [
                    {
                        text: `${title} - ${currentPage.toString()} of ${pageCount}`,
                        alignment: "center",
                        fontSize: 10,
                        color: "#aaa",
                    },
                ];
            },
            content: docs.flatMap((doc, key) => (
                [
                    {
                        columns: [
                            {
                                image: 'companyLogo'
                            },
                            {
                                width: 350,
                                text: ""
                            },
                            { qr: `${JSON.stringify({document_id:doc.id, document_number:doc.document_number})}`, fit: '60' },
                        ]
                    },
                    {
                        columns: [
                            {
                                width: 540,
                                alignment: 'right',
                                fontSize: 9,
                                color: 'blue',
                                text: "(Scan to open on mobile app)",
                                margin: [0, 5, 0, 0],
                            },
                        ]
                    },
                    {text: `${title.toUpperCase()} FORM`, style: 'header'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto', "*", 'auto', 'auto'],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        text: 'Reference Number : ',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.reference_number,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Number : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.document_number,
                                        color: "blue",
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: "Transaction Description : ",
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: `${doc.description}`,
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Document Date : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: moment(doc.document_date).format(resources.date_format.DEFAULT),
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                                [
                                    {
                                        text: 'Profit/Cost Center',
                                        fontSize: 9,
                                        bold: true
                                    },
                                    {
                                        text: doc.profit_cost_center ? JSON.parse(doc.profit_cost_center).label : '',
                                        fontSize: 9,
                                        bold: false
                                    },
                                    {
                                        text: "Status : ",
                                        fontSize: 9,
                                        bold: true,
                                        alignment: 'right'
                                    },
                                    {
                                        text: doc.approval_status,
                                        fontSize: 9,
                                        bold: false,
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders'
                    },

                    {text: 'Line Items', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ["*", 70, 70, 70, 70],
                            headerRows: 1,
                            body: [
                                [
                                    {text: 'Item Code / Description', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'Qty', style: 'tableHeader', alignment: 'right', margin: [0, 7, 0, 7]},
                                    {text: 'UoM', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'From Location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                    {text: 'To Location', style: 'tableHeader', alignment: 'center', margin: [0, 7, 0, 7]},
                                ],
                                ...item_location_transfer_line_items.map((itemLocationTransferLineItem) => [
                                    itemLocationTransferLineItem.item.label,
                                    {text: itemLocationTransferLineItem.quantity, alignment: 'right'},
                                    {text: itemLocationTransferLineItem.uom.label, alignment: 'center'},
                                    {text: itemLocationTransferLineItem.from_location.label, alignment: 'center'},
                                    {text: itemLocationTransferLineItem.to_location.label, alignment: 'center'},
                                ])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                            },
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                            },
                        },
                    },

                    {text: 'Transaction Logs', style: 'subheader'},
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: ['auto','auto','*'],
                            headerRows: 0,
                            body:
                                doc.transaction_logs.slice(0).reverse().map((transaction_log) => ([
                                    {
                                        text: `${transaction_log.action} by`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `${transaction_log.by}`,
                                        fontSize: 9,
                                    },
                                    {
                                        text: `at ${transaction_log.at}`,
                                        fontSize: 9,
                                    },
                                ]))

                        },
                        layout: 'noBorders',
                        pageBreak: key<docs.length-1 ? "after" : null
                    },

                ]
            )),

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 0, 0, 40],
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 11,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    fontSize: 9,
                    bold: false,
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'black'
                }
            },
            images: {
                companyLogo: active_company.logo_source && Util.file_exists(active_company.logo_source) ? active_company.logo_source : active_company.default_logo_source,
            }
        };
        pdfMake
            .createPdf(docDefinition)
            .download(`${title}.pdf`);
    };

}

export default PDF;
