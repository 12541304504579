export const resources = {
    app: {
        NAME: "Finximus"
    },
    locale: {
        ENGLISH: "en",
        CHINESE: "cn",
    },
    date_format: {
        DEFAULT: "D-MMM-YYYY",
        INPUT: "YYYY-MM-DD",
        INPUT_DATE: "DD/MM/YYYY",
        SYSTEM: "YYYY-MM-DD HH:mm:ss",
    },
    status: {
        IDLE: "idle",
        LOADING: "loading",
        SILENT_LOADING: "silent_loading",
        ERROR: "error",
        SUCCESS: "success",
    },
    slice: {
        AUTH: "auth"
    },
    omnibar_ds_types: {
        ITEM: 'Item',
        UNIT_OF_MEASURE: 'UOM',
        LOCATION: 'Location',
        FROM_LOCATION: 'From Location',
        TO_LOCATION: 'To Location',
        PROFIT_COST_CENTER: 'Profit Cost Center',
        ITEM_CONDITION: 'Item Condition',
    },
    industries: {
        LAUNDRY: 'Laundry Services',
        CAR_WASH: 'Car Wash',
        RESTAURANT : 'Cafe & Restaurant',
        OTHERS: 'Others',
    },
    default_line_items_count: 5,
    default_dataset: Array(1000).fill("").map(() => new Array(10).fill("")),
    lang: {
        en: {
            label: {
                REQUIRED: "*",
                EMAIL_ADDRESS: "Email Address",
                PASSWORD: "Password",
                CONFIRM_PASSWORD: "Confirm Password",
                SWITCH_MODE: "Switch Mode",
                IMPORT: "Import",
                READ_ONLY: "Read-Only",
                SELECT_USER_FROM_OPTIONS: "Select user from the options",
                REGISTERED_EMAIL_ADDRESS: "Registered email address",
                ASSIGNED_ROLE: "Role/s assigned to the selected user",
                CREATE_NEW: "Create new...",
                OLD_PASSWORD: "Old Password",
                ACTIVE_COMPANY: "Active Company",
            },
            placeholder: {
                ENTER_EMAIL_ADDRESS: "Enter your email address",
                ENTER_PASSWORD: "Enter your password",
                ENTER_OLD_PASSWORD: "Enter your old password",
                ENTER_NEW_PASSWORD: "Enter your new password",
                ENTER_CONFIRM_PASSWORD: "Confirm your password",
                ENTER_CONFIRM_NEW_PASSWORD: "Confirm your new password",
                ENTER_FIRST_NAME: "Enter your first name",
                ENTER_LAST_NAME: "Enter your last name",
                ENTER_COMPANY_CODE: "Company / Business Code",
                ENTER_COMPANY_NAME: "Company / Business Name",
                ENTER_COMPANY_ADDRESS: "Company / Business Address",
                SELECT_COUNTRY: "Country",
                COMPANY_CODE: "Company / Business Code",
                COMPANY_NAME: "Company / Business Name",
                ENTER_UOM_CONVERSION_CODE: "UOM Conversion Code",
                ENTER_UOM_CONVERSION_DESCRIPTION: "UOM Conversion Name",
                ENTER_QUANTITY: "Enter Quantity",
                ENTER_LOCATION_CODE: "Enter specific location code under selected location group",
                ENTER_LOCATION_DESCRIPTION: "Enter specific location name under selected location group",
                ENTER_ITEM_CODE: "Enter item code",
                ENTER_ITEM_DESCRIPTION: "Enter item name",
                ENTER_INITIAL_COST: "Enter initial unit cost",
                ENTER_LATEST_COST: "Enter latest unit cost",
                ENTER_PART_NUMBER: "Enter model / part number",
                ENTER_CUSTOM_BARCODE_NUMBER: "Enter Custom Barcode Number",
                ENTER_SELLING_PRICE: "Enter Selling Price",
                ENTER_PROMO_CODE: "Enter Promo Code",
                ADD_NEW_SELECT_UOM: "Add new / select UOM",
                ADD_NEW_SELECT_LOCATION_TYPE: "Add new / select location group",
                ADD_NEW_SELECT_ITEM_CLASS: "Add new / select item class",
                SELECT_TRANSACTION_UOM: "Select default UOM",
                SELECT_UOM_CONVERSION: "Select UOM conversion/s",
                SELECT_ITEM_COSTING: "Select Item costing",
                SELECT_TAX: "Select Sales Tax",
                SELECT_DISCOUNT: "Select Sales Discount",
                SELECT_INVENTORY_SOURCE: "Select Inventory source",
                SELECT_ITEM_FINISHED_PRODUCT: "Select finished product",
                SELECT_ITEM_COMPONENT: "Select item to add as a component",
                SELECT_ITEM: "Select item",
                SELECT_ITEM_CLASS: "Select item class",
                SELECT_GL_ACCOUNT: "Select GL Account",
                TYPE_USER_EMAIL: "Email address",
                TYPE_FIRST_NAME: "First name",
                TYPE_LAST_NAME: "Last name",
                SELECT_PROFIT_COST_CENTER_TYPE: "Profit / cost center type",
                TYPE_CODE: "Type a code here",
                TYPE_NAME: "Type a name here",
                SELECT_MAKER: "Select Maker/s",
                SELECT_AUTHORIZER: "Select Authorizer/s",
                SELECT_AUTHORIZER_TO_UNASSIGN: "Select Authorizer/s to Remove",
                SELECT_AUTHORIZER_NAME: "Only usernames with assigned Authorizer role are shown.",
                ENTER_PROFIT_COST_CENTER_CODE: "Profit/Cost Center Code",
                ENTER_PROFIT_COST_CENTER_DESCRIPTION: "Profit/Cost Center Name",
                SELECT_PROFIT_COST_CENTER_USERS: "Select users under this profit/cost center",
                SELECT_TRANSFER_FROM: "Transfer from",
                SELECT_TRANSFER_TO: "Transfer to",
                SELECT_FINISHED_GOOD_ITEM: "Select Finished Good Item",
                SELECT_PRODUCT_UOM: "Select Product UoM",
                ENTER_PRODUCT_QUANTITY: "Enter Product Quantity",
                SELECT_LOCATION: "Select Location",
                SELECT_TRANSACTION_SOURCE: "Select Transaction Source",
                SELECT_REQUESTING_UNIT: "Select Requesting Unit",
                SELECT_RECEIVING_UNIT: "Select Receiving Unit",
                REQUESTING_UNIT: "Requesting Unit",
                SELECT_ISSUING_UNIT: "Select Issuing Unit",
                PICK_A_DATE: "Doc. Date",
                POSTING_DATE: "Posting date",
                ENTER_TRANSACTION_REFERENCE_NUMBER: "Ref. number",
                ENTER_TRANSACTION_DESCRIPTION: "Transaction description",
                SELECT_FINISHED_GOODS_TO_PRODUCE: "Select Finished Goods to Produce",
                STANDARD_PRODUCTION: "Standard Production",
                NUMBER_OF_BATCHES: "Number of Batches",
                YIELD_UOM: "Yield UOM",
                YIELD_QUANTITY: "Yield Quantity",
                ACTUAL_PRODUCTION: "Actual Production",
                DIRECT_LABOR_COST: "Enter Direct Labor Cost",
                OVERHEAD_COST: "Enter Overhead Cost",
                TOTAL_PRODUCTION_COST: "Total Production Cost",
                FINISHED_GOOD_UNIT_PRICE: "Finished Goods Unit Cost",
                FINISHED_GOODS_PRODUCED: "Finished Goods Produced",
                ENTER_REORDER_POINT: "Enter Reorder Point",
                ENTER_DISCOUNT_START: "Discount Start",
                ENTER_DISCOUNT_EXPIRY: "Discount Expiry",
                SHIPMENT_COST_PER_INVOICE: "Shipment Cost per Invoice",
                FREIGHT_AND_INSURANCE: "Freight & Insurance",
                CUSTOMS_DUTIES: "Customs Duties",
                VALUE_ADDED_TAX: "Value-Added Tax",
                DOCUMENTARY_STAMPS: "Documentary Stamps",
                DEMURRAGE_AND_STORAGE: "Demurrage & Storage",
                BROKERAGE_FEE: "Brokerage Fee",
                WHARFAGE_AND_ARRASTRE: "Wharfage & Arrastre",
                OTHER_CHARGES: "Other Charges",
                TOTAL_IMPORT_COST: "Total Import Cost",
                TOTAL_INVENTORY_COST: "Total Inventory Cost",
                SELECT_FORMAT: "Select Format",
                FROM_DATE: "From Date",
                TO_DATE: "To Date",
                TURN_MOBILE_PHONE_TO_LANDSCAPE: "Turn your mobile phone's orientation to landscape to view or edit line items.",
                PICKUP_DATE: "Pick-up Date",
                DELIVERY_DATE: "Delivery Date",
                APPOINTMENT_DATE: "Appointment Date",
                POSITION: "Position / Designation",
                SHIFT_START: "Shift Start",
                SHIFT_END: "Shift End",
                CASHIER_NAME: "Cashier Name",
                SELECT_INDUSTRY: "Industry",
                SELECT_DAY: "Select Day",
                ENTER_SERVICE_NAME: "Enter Service Name",
                ENTER_SERVICE_CODE: "Enter Service Code",
                ASSIGNMENT_OF_PERSONNEL: 'Assignment of Personnel',
                ENTER_TAX_CODE: 'Enter Tax Code',
                ENTER_TAX_DESCRIPTION: 'Enter Tax Description',
                ENTER_TAX_RATE: 'Enter Tax Rate',
                ENTER_TAX_COMPUTATION: 'Enter Tax Computation',
                CASH_REMITTANCE_INTERVAL: 'Cash Remittance Interval (No. of Days)'
            },
            text: {
                DONT_HAVE_ACCOUNT_YET: "Don't have an account yet ?",
                CREATE_ACCOUNT: "Create New Account",
                CREATE_SUBSCRIPTION: "Create New Subscription",
                BASIC_INFO: "Basic Info",
                SELECT_MODULES: "System Modules",
                COMPANY_DETAILS: "Company / Business Info",
                MY_DOCUMENTS: "My Documents",
                MY_MESSAGES: "Inbox - Unread",
                SYSTEM_IMPLEMENTATION: "System Implementation",
                SYSTEM_IMPLEMENTATION_IS_ONGOING: "System Implementation is Ongoing",
                DRAFT: "Draft",
                REJECTED: "Rejected",
                SUBMITTED_FOR_APPROVAL: "Submitted for Approval",
                FOR_MY_APPROVAL: "For my Approval",
                APPROVED: "Approved",
                VOIDED: "Voided",
                UP_TO: "up to",
                NOTE_SWITCH_MODE_LABEL: 'Switch Mode',
                NOTE_SWITCH_MODE_VALUE: 'Click to select either "Import" or "Read-Only". Select Import if you wish to avoid single data entry.',
                NOTE_SPREADSHEET_LABEL: 'Spreadsheet',
                NOTE_SPREADSHEET_VALUE: 'You can copy the data from excel file and paste it directly to the spreadsheet below to upload.',
                NOTE_CREATE_LABEL: 'Create',
                NOTE_CREATE_VALUE: 'Click "Create" button after you have completed all the required details from input fields below.',
                NOTE_FREEZE_PANE_LABEL: 'Freeze Pane',
                NOTE_FREEZE_PANE_VALUE: 'Click "Freeze Pane" button to keep specific columns visible when you scroll the spreadsheet. You must select or focus a cell first before clicking the button.',
                NOTE_DROPDOWN_LABEL: 'Dropdown',
                NOTE_DROPDOWN_VALUE: 'You can select from the options or type series of letters to generate an auto-lookup list.',
                NOTE_SEND_INVITE_LABEL: 'Send Invite',
                NOTE_SEND_INVITE_VALUE: 'Click "Send Invite" button after you have completed all the required details from input fields below.',
                NOTE_SAVE_LABEL: 'Save',
                NOTE_SAVE_VALUE: 'Click "Save" button after you have completed all the required details from input fields below.',
                NOTE_ASSIGN_LABEL: 'Assign',
                NOTE_ASSIGN_VALUE: 'Click "Assign" button after you have completed all the required details from input fields below.',
                NOTE_AUTHORIZERS_FIELD_LABEL: 'Select Authorizer/s Field',
                NOTE_AUTHORIZERS_FIELD_VALUE: 'Only usernames with assigned role "Authorizer" will be displayed.',
                NOTE_MAKERS_FIELD_LABEL: 'Select Maker/s Field',
                NOTE_MAKERS_FIELD_VALUE: 'Only usernames with assigned role "Maker" will be displayed.',
                NOTE_INLINE_EDITING_FIELD_LABEL: 'Inline Cell Editing',
                NOTE_INLINE_EDITING_FIELD_VALUE: 'You can edit a cell through these steps: 1. Double click a cell you want to edit. 2. Type the necessary changes. 3. Press <Enter> key (for Windows) or <Return> key (for Mac) for the changes to save. You can only edit a cell in Import mode.',
                NOTE_PASTING_DATA_LABEL: 'Pasting Data',
                NOTE_PASTING_DATA_VALUE: 'When pasting data from an excel worksheet, single-click on the cell to focus. Press <Control>+V (for Windows) or <Command>+V (for Mac). You can only paste data in Import mode.',
                NOTE_DOCUMENT_TYPE_LABEL: 'Document Types',
                NOTE_DOCUMENT_TYPE_VALUE: 'You can ENABLE the approval of all document types except ITEM LOCATION TRANSFER (which is permanently disabled due to the nature of transaction).',
                CHANGE_PASSWORD: 'Change Password',
                LOGOUT: 'Logout',
                UNIT_OF_MEASURE: "Unit of Measure Conversion",
                LOCATION: "Manage Locations",
                ITEM_CLASS: "Manage Item Classes",
                ORDERING_AND_APPOINTMENT_APP: "Ordering & Appointment App",
                ITEM: "Manage Inventory Items",
                PROFIT_COST_CENTER_TYPES: "( Type: Department, Branch, Project )",
                SELECT_PARENT_LOCATION : "Click the appropriate parent location from the location tree to create specific sub location/s.",
                CREATE_SUBLOCATION: "Create a sublocation under parent location ",
                COPYRIGHT: "© 2021 Finximus. All rights reserved.",
                CLICK_UPDATE_AFTER_EDITING: "Click Update button after editing the field/s.",
                CHOOSE_FILE: "Upload inventory image here (file format png, jpeg, gif)",
                CHOOSE_SERVICE_FILE: "Upload service image here (file format png, jpeg, gif)",
                FINISHED_GOODS_COMPONENT: "Finished Goods Components",
                INVENTORY_MOBILE_SCANNER: "Inventory Mobile Scanner",
                MANAGE_BARCODE_QR_CODE: "Barcode / QR Code Printing",
                ASSIGN_ROLE: "Select role/s",
                ROLE: "Role",
                CONTROL_ACCESS: "Control Access",
                PROFIT_COST_CENTER: "Organize Profit/Cost Centers",
                USER: "Manage Users",
                PERMISSION: "User Permission",
                MANAGE_TRANSACTIONS: "Manage Users' Transactions",
                ASSIGN_AUTHORIZER: "Assign Authorizer",
                UNASSIGN_AUTHORIZER: "Remove Authorizer",
                TAXES: "Taxes",
                DISCOUNTS: "Discounts",
                ASSIGN_MAKER: "Assign Maker",
                DEPARTMENT: "Department: ",
                BRANCH: "Branch: ",
                PROJECT: "Project: ",
                ITEM_LOCATION_TRANSFER: "Item Location Transfer",
                STOCK_REQUEST: "Stock Purchase Request",
                STOCK_REQUEST_SUBTITLE: "This form is use for requesting items to purchase from external suppliers.",
                STOCK_TRANSFER: "Stock Transfer Request",
                FINISHED_GOOD_PRODUCED: "Finished Goods Produced",
                STOCK_ACCEPTANCE: "Stock Acceptance",
                IMPORTED_ITEMS_RECEIPT: "Imported Items Receipt",
                STOCK_ADJUSTMENT: "Stock Adjustment",
                ATTACHMENTS: "ATTACHMENTS",
                COMMENTS: "COMMENTS",
                TRANSACTION_LOGS: "TRANSACTION LOGS",
                STOCK_ISSUANCE: "Stock Issuance",
                STOCK_ISSUANCE_SUBTITLE: "This form is use for issuance of items from stock transfer request and to production transactions.",
                STOCK_ACCEPTANCE_SUBTITLE: "This form is use for receiving of items from direct receipt, stock purchase request and stock transfer request transactions.",
                QUANTITY: 'Quantity',
                ACCEPT_QUANTITY: 'Accept Quantity',
                ISSUE_QUANTITY: 'Issue Quantity',
                CANCEL_QUANTITY: 'Cancel Quantity',
                GENERAL_SETTINGS: 'General Settings',
                BEGINNING_BALANCE: 'Beginning Balance',
                STOCK_COUNT: 'Stock Count',
                ITEM_HISTORY: "Item History",
                SALES: "Sales",
                CASH_REMITTANCE: "Cash Remittance",
                ZREADING: "Z-Reading",
                END_OF_DAY: "End of Day",
                DELIVERY_RECEIPT: "Delivery Receipt",
                ISSUED_ITEMS_FOR_ACCEPTANCE: "Issued Items for Acceptance",
                STOCK_MOVEMENT: "Stock Movement",
                STOCK_ON_HAND: "Stock on Hand",
                INVENTORY_ITEM_BY_STATUS: "Inventory Items by Status",
                ITEM_CONDITIONS: "Item Conditions",
                DOCUMENT: "Document",
                PENDING_TRANSACTIONS: 'Pending Transactions',
                MY_SUBSCRIPTION: 'My Subscription',
                MANAGE_ITEM_CLASSES_SUBTITLE: 'Create or edit item classes to control the items that can be accessed by user/s under the assigned profit cost center/s.',
                ORDERING_AND_APPOINTMENT_APP_SUBTITLE: 'A mobile application for customers to create orders and make an appointment for delivery or pickup.',
                PERMISSION_SUBTITLE: 'Assign which functions or menus can be accessed by user on the sidebar.',
                ORDERS_AND_APPOINTMENTS: 'Order & Appointment App',
                POINT_OF_SALE: 'Point of Sale',
                MANAGE_CASHIER_SHIFTS: 'Manage Cashier Shifts',
                POS_TERMINAL_SETTINGS: 'POS Terminal Settings',
                POS_ITEMS_AND_SERVICES: 'POS Items and Services',
                ITEMS_TO_SELL: 'Item Inventory to Sync in POS',
                SERVICES_TO_SELL: 'Service/s to Sell',
                POS_TERMINAL: 'POS Terminal',
                LAUNDRY_MACHINE_SET: 'Laundry Machine Set',
                CYCLE_COUNT_RECONCILIATION: 'Laundry Cycle Count Reconciliation',
                MANAGE_SUBSCRIPTIONS: 'Manage Subscriptions',
            },
            button: {
                LOGIN: "Login",
                CREATE_NEW_ACCOUNT: "Create New Account",
                SEND_PASSWORD_RESET_LINK: "Send Password Reset Link",
                FORGOT_PASSWORD: "Forgot Password ?",
                RESET_PASSWORD: "Reset Password",
                SET_PASSWORD: "Set Password and Activate",
                CHANGE_PASSWORD: "Change Password",
                CREATE: "Create",
                SAVE_AS_PDF: "Save as PDF",
                SAVE_AS_XLSX: "Save as XLSX",
                FREEZE_PANE: "Freeze Pane",
                UNFREEZE_PANE: "Unfreeze Pane",
                SEND_INVITE: "Send Invite",
                SETUP_PASSWORD: "Set-up your password",
                MANAGE_PERMISSION: "Manage Permission",
                SAVE: "Save",
                SAVE_SETTINGS: "Save Settings",
                IMPORT: "Import",
                CLEAR: "New / Reset",
                CLEAR_CELLS: "Clear All Cells",
                CLEAR_SELECTED_CELLS: "Clear Selected Cells",
                DELETE: "Delete",
                UPDATE: "Update",
                UNASSIGN: "Remove",
                ASSIGN: "Assign",
                DELETE_ROWS: "Delete Selected",
                CLEAR_PRINT_QUEUE: "Clear Print Queue",
                CREATE_DRAFT: "Create Draft",
                SUBMIT: "Submit",
                APPROVE: "Approve",
                REJECT: "Reject",
                PRINT: "Print",
                VOID: "Void",
                VOID_SELECTED_ROWS: "Void Selected",
                CANCEL: "Cancel",
                SELECT_ALL: "Select All",
                UNSELECT_ALL: "Un-select All",
                ADD_MORE_ITEMS: "Add more items",
                REMOVE_ALL_ITEMS: "Reset line items",
                PROCESS_SELECTED_DOCUMENTS: "Process Selected Document/s",
                ADD_SCHEDULE: "Add Selected Schedule",
                BYPASS_STOCK_COUNT_SCHEDULE: "Bypass Stock Count Schedule",
                BYPASS_YEAR_END_CLOSING: "Bypass Year-End Closing",
                GENERATE: "Generate",
                REGISTER: "Register",
                RETURN_TO_LOGIN: "Return to Login",
                SKIP_PROCESS: "Next Step",
                FINAL_PROCESS: "Finish",
                REFRESH_DATA: "Refresh Data",
                TRANSFER_DOCUMENTS: "Transfer Selected Document/s",
                DUPLICATE_SELECTED_ROWS: "Duplicate Selected",
                DOWNLOAD_QR_CODE: "Download QR Image (Android)",
                SYNC_LAUNDRY_MACHINE_SET_DATA: "Sync Laundry Machine Sets",
                REMOVE_FROM_LIST: "Remove from List",
                SAVE_AND_INITIALIZE: "Save & Initialize",
                FINALIZE: "Finalize",
            },
            breadcrumb: {
                SYSTEM_SETTINGS: [{text: "Dashboard", href: "/system-settings"}],
                WAREHOUSE_INVENTORY: [{text: "Dashboard", href: "/warehouse-inventory"}],
                POINT_OF_SALE: [{text: "Dashboard", href: "/point-of-sale"}],
                ADMIN_PANEL: [{text: "Dashboard", href: "/admin-panel"}],
                MANAGE_SUBSCRIPTIONS: [{text: "Admin Panel > Manage Subscriptions", href: "/admin-panel/subscriptions"}],
                USER: [{text: "Global Settings > Manage Users", href: "/system-settings/user"}],
                PROFIT_COST_CENTER: [{text: "Global Settings > Profit / Cost Centers",href: "/system-settings/profit-cost-center"}],
                PERMISSION: [{text: "Global Settings > User Permission", href: "/system-settings/permission"}],
                AUTHORIZER: [{text: "Global Settings > Assign Authorizer", href: "/system-settings/authorizer"}],
                TAXES: [{text: "Global Settings > Taxes", href: "/system-settings/taxes"}],
                DISCOUNTS: [{text: "Global Settings > Discounts", href: "/system-settings/discounts"}],
                MAKER: [{text: "Global Settings > Assign Maker", href: "/system-settings/maker"}],
                GENERAL_SETTINGS: [{text: "Warehouse Inventory > General Settings", href: "/warehouse-inventory/general-settings"}],
                UNIT_OF_MEASURE: [{text: "Warehouse Inventory > UoM Conversion", href: "/warehouse-inventory/unit-of-measure"}],
                LOCATION: [{text: "Warehouse Inventory > Inventory Location", href: "/warehouse-inventory/location"}],
                ITEM_CLASS: [{text: "Warehouse Inventory > Item Class", href: "/warehouse-inventory/item-class"}],
                ITEM: [{text: "Warehouse Inventory > Manage Inventory Items", href: "/warehouse-inventory/item"}],
                FINISHED_GOOD_COMPONENT: [{text: "Warehouse Inventory > FG Components", href: "/warehouse-inventory/finished-goods-components"}],
                MANAGE_BARCODE_QR_CODE: [{text: "Warehouse Inventory > Inventory Mobile Scanner", href: "/warehouse-inventory/inventory-mobile-scanner"}],
                MANAGE_TRANSACTIONS: [{text: "Warehouse Inventory > Manage Users' Transactions", href: "/warehouse-inventory/manage-transactions"}],
                ORDERING_AND_APPOINTMENT_APP: [{text: "Global Settings > Ordering & Appointment App", href: "/warehouse-inventory/ordering-and-appointment-app"}],
                ITEM_LOCATION_TRANSFER: [{text: "Warehouse Inventory > Item Location Transfer", href: "/warehouse-inventory/item-location-transfer"}],
                STOCK_REQUEST: [{text: "Warehouse Inventory > Stock Purchase Request", href: "/warehouse-inventory/stock-request"}],
                STOCK_TRANSFER: [{text: "Warehouse Inventory > Stock Transfer Request", href: "/warehouse-inventory/stock-transfer"}],
                FINISHED_GOOD_PRODUCED: [{text: "Warehouse Inventory > Finished Goods Produced", href: "/warehouse-inventory/finished-goods-produced"}],
                STOCK_ACCEPTANCE: [{text: "Warehouse Inventory > Stock Acceptance", href: "/warehouse-inventory/stock-acceptance"}],
                IMPORTED_ITEMS_RECEIPT: [{text: "Warehouse Inventory > Imported Items Receipt", href: "/warehouse-inventory/imported-items-receipt"}],
                STOCK_ADJUSTMENT: [{text: "Warehouse Inventory > Stock Adjustment", href: "/warehouse-inventory/stock-adjustment"}],
                STOCK_ISSUANCE: [{text: "Warehouse Inventory > Stock Issuance", href: "/warehouse-inventory/stock-issuance"}],
                BEGINNING_BALANCE: [{text: "Warehouse Inventory > Beginning Balance", href: "/warehouse-inventory/beginning-balance"}],
                STOCK_COUNT: [{text: "Warehouse Inventory > Stock Count", href: "/warehouse-inventory/stock-count"}],
                ITEM_HISTORY: [{text: "Warehouse Inventory > Item History", href: "/warehouse-inventory/item-history"}],
                SALES: [{text: "Point-of-Sale > Sales", href: "/point-of-sale/sales"}],
                CASH_REMITTANCE: [{text: "Point-of-Sale > Cash Remittance", href: "/point-of-sale/cash-remittance"}],
                ZREADING: [{text: "Point-of-Sale > Z-Reading", href: "/point-of-sale/z-reading"}],
                END_OF_DAY: [{text: "Point-of-Sale > End of Day", href: "/point-of-sale/end-of-day"}],
                DELIVERY_RECEIPT: [{text: "Warehouse Inventory > Delivery Receipt", href: "/warehouse-inventory/delivery-receipt"}],
                ISSUED_ITEMS_FOR_ACCEPTANCE: [{text: "Warehouse Inventory > Issued Items for Acceptance", href: "/warehouse-inventory/issued-items-for-acceptance"}],
                STOCK_MOVEMENT: [{text: "Warehouse Inventory > Stock Movement", href: "/warehouse-inventory/stock-movement"}],
                STOCK_ON_HAND: [{text: "Warehouse Inventory > Stock on Hand", href: "/warehouse-inventory/stock-on-hand"}],
                INVENTORY_ITEM_BY_STATUS: [{text: "Warehouse Inventory > Inventory Items by Status", href: "/warehouse-inventory/inventory-item-by-status"}],
                ITEM_CONDITIONS: [{text: "Warehouse Inventory > Item Conditions", href: "/warehouse-inventory/item-conditions"}],
                MANAGE_CASHIER_SHIFTS: [{text: "Point-of-Sale > Manage Cashier Shifts", href: "/point-of-sale/manage-cashier-shifts"}],
                POS_TERMINAL_SETTINGS: [{text: "Point-of-Sale > POS Terminal Settings", href: "/point-of-sale/pos-terminal-settings"}],
                POS_ITEMS_AND_SERVICES: [{text: "Point-of-Sale > POS Items and Services", href: "/point-of-sale/pos-items-and-services"}],
            },
            boolean: {
                TRUE: 'yes',
                FALSE: 'no',
            },
            notifier: {
                DRAFT: "draft",
                REJECTED: "rejected",
                FOR_APPROVAL: "for-approval",
                APPROVED: "approved",
                DOCUMENTS_TO_APPROVE: "documents-to-approve",
                VOIDED: "voided",
            },
            message: {
                ITEM_CODE_RANGE_IS_REQUIRED: 'Item code range is required.',
                ITEM_CODE_RANGE_IS_INVALID: 'Item code range is invalid.',
                PRINT_QUEUE_BARCODE_IS_EMPTY: 'To print barcode item/s, click first "Add Barcode item to Print Queue" button.',
                PRINT_QUEUE_QRCODE_IS_EMPTY: 'To print QR code item/s, click first "Add QR Code item to Print Queue" button.',
                IMPORT_LIMIT_REACHED: 'You can only import up to  700 rows per batch.',
                CONSOLIDATION_MUST_BELONG_TO_SAME_UNIT : 'Selecting different profit/cost centers in the "Transfer from" and "Transfer to" are not allowed.',
                CONSOLIDATION_MUST_BELONG_TO_SAME_REQUESTING_UNIT : 'Selecting different requesting unit are not allowed.',
                ITEM_HAS_NO_SERIAL_NUMBER : 'The serial number of this item is turned off/ disabled in the Manage Inventory Items.',
                ITEM_HAS_NO_SERIAL_NUMBER_AND_EXPIRY : 'The serial number and expiry of this item is turned off/ disabled in the Manage Inventory Items.',
                BARCODE_FIELD_IS_REQUIRED : 'Please select a barcode field.',
                QUANTITY_TO_PRINT_EXCEEDED_ASSIGNED_QUANTITY : 'The quantity to print exceeded the actual quantity of items with assigned serial numbers.',
                LOADING_SAVING_IN_PROGRESS : "Saving/Loading of transaction/s in progress...",
                STOCK_COUNT_DATE_INVALID : "Stock count date is invalid. Please use the current stock count schedule.",
                PASSWORD_DOES_NOT_MATCH : "Password must match.",
                ITEM_TO_PRINT_REQUIRED : "Please select an item to print.",
                IMPORT_USERS_QUEUED : "User/s has been queued for saving.",
                IMPORT_USERS_FINISHED : "The system has finished importing the user/s.",
                IMPORT_PROFIT_COST_CENTERS_QUEUED : "Profit cost center/s has been queued for saving.",
                IMPORT_PROFIT_COST_CENTERS_FINISHED : "The system has finished importing the profit cost center/s.",
                IMPORT_TAXES_QUEUED : "Tax/es has been queued for saving.",
                IMPORT_TAXES_FINISHED : "The system has finished importing the tax/es.",
                IMPORT_ITEMS_QUEUED : "Item/s has been queued for saving.",
                IMPORT_ITEMS_FINISHED : "The system has finished importing the item/s.",
                IMPORT_ITEM_CLASSES_QUEUED : "Item class/es has been queued for saving.",
                IMPORT_ITEM_CLASSES_FINISHED : "The system has finished importing the item class/es.",
                IMPORT_UOM_CONVERSION_QUEUED : "Unit of measure conversion/s has been queued for saving.",
                IMPORT_UOM_CONVERSION_FINISHED : "The system has finished importing the unit of measure conversion/s.",
                IMPORT_STOCK_ISSUANCE_QUEUED : "Stock issuance/s has been queued for saving.",
                IMPORT_STOCK_ISSUANCE_FINISHED : "The system has finished importing the stock issuance/s.",
                IMPORT_BEGINNING_BALANCE_QUEUED : "Beginning balance/s has been queued for saving.",
                IMPORT_BEGINNING_BALANCE_FINISHED : "The system has finished importing the beginning balance/s.",
                IMPORT_ITEM_LOCATION_TRANSFER_QUEUED : "Item location transfer/s has been queued for saving.",
                IMPORT_ITEM_LOCATION_TRANSFER_FINISHED : "The system has finished importing the item location transfer/s.",
                IMPORT_FINISHED_GOOD_COMPONENTS_QUEUED : "Finished good component/s has been queued for saving.",
                IMPORT_FINISHED_GOOD_COMPONENTS_FINISHED : "The system has finished importing the finished good component/s.",
                SESSION_EXPIRED : "Your session has already expired.",
                NO_RECORD_FOUND: "No Record Found."
            },
            worksheet: {
                default_alignment: {
                    MANAGE_SUBSCRIPTIONS: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    PROFIT_COST_CENTER: ["left", "left", "left", "left", "left", "left"],
                    USER: ["left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    PERMISSION: ["left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    MANAGE_TRANSACTIONS: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    AUTHORIZER: ["left", "left", "left", "left", "left", "left", "left", "left"],
                    TAXES: ["left", "left", "left", "left", "right", "left", "left", "left"],
                    DISCOUNTS: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    UNIT_OF_MEASURE: ["left", "left", "left", "right", "left", "right", "left", "left", "left"],
                    LOCATION: ["left", "left", "left", "left", "left", "left", "left"],
                    ITEM_CLASS: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    ITEM: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "right", "left", "left", "left", "left", "left"],
                    FINISHED_GOOD_COMPONENT: ["left", "left", "left", "left", "right", "left", "left", "left", "right", "left"],
                    MANAGE_BARCODE_QR_CODE: ["left", "left", "left", "left", "left", "left", "left"],
                    ITEM_LOCATION_TRANSFER: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_REQUEST: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_TRANSFER: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    DOCUMENT: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    ORDER: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_ACCEPTANCE: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    IMPORTED_ITEMS_RECEIPT: ["left", "left", "left", "left", "left", "left", "left", "left", "right", "right", "right", "right", "right", "right", "right", "right", "right", "right", "right", "left"],
                    STOCK_ADJUSTMENT: ["left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_ISSUANCE: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    FINISHED_GOOD_PRODUCED: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    BEGINNING_BALANCE: ["left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_COUNT: ["left", "left", "left", "left", "left", "left", "left", "left"],
                    ITEM_HISTORY: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "right", "left"],
                    SALES: ["left", "left", "left", "left", "left", "left", "right", "right", "right", "left", "left", "right", "right", "right", "left"],
                    SALES_SUMMARIZED: ["left", "left", "left", "left", "left", "left", "left", "right", "right", "right", "left", "left", "right", "right", "right", "left"],
                    CASH_REMITTANCE: ["left", "left", "left", "right", "left", "left"],
                    ZREADING: ["left", "left", "left", "left", "left", "left", "left", "right", "right", "right", "left", "left", "right", "right", "right", "left"],
                    END_OF_DAY: ["left", "left", "left", "left", "left", "left", "left", "right", "right", "right", "left", "left", "right", "right", "right", "left"],
                    DELIVERY_RECEIPT: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "right"],
                    DELIVERY_RECEIPT_DETAILED: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "right", "left", "left", "left", "right", "right", "right", "left"],
                    ISSUED_ITEMS_FOR_ACCEPTANCE: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "right", "left", "left", "left", "right", "right", "right", "right", "right", "left"],
                    STOCK_MOVEMENT: ["left", "left", "left", "left", "left", "left", "left", "left", "right", "right", "right"],
                    STOCK_ON_HAND: ["left", "left", "left", "left", "left", "left", "right", "right", "right"],
                    INVENTORY_ITEM_BY_STATUS: ["left", "left", "left", "left", "right", "right", "right","left","left"],
                    ITEM_CONDITIONS: ["left", "left", "left", "left", "left", "center", "right", "left", "left"],
                    ORDERING_AND_APPOINTMENT_APP: ["left", "left", "left", "left", "left", "left", "left", "left"],
                    MANAGE_CASHIER_SHIFTS: ["left", "left", "left", "left", "left", "left", "left", "left"],
                },
                default_header: {
                    MANAGE_SUBSCRIPTIONS: ["id", "email", "subscriber name", "company name", "industry", "country", "expiry date", "warehouse inventory", "point of sale", "no. of pos terminal"],
                    PROFIT_COST_CENTER: ["id", "profit/cost center type", "profit/cost center code", "profit/cost center description", "created at", "updated at"],
                    USER: ["id", "email", "first name", "last name", "assigned roles", "position", "account status", "login status", "last logged out at"],
                    PERMISSION: ["id", "email", "first name", "last name", "assigned roles", "position", "account status", "created at", "permissions updated at"],
                    MANAGE_TRANSACTIONS: ["id", "document type", "document number", "document date", "source", "approval status", "transaction description", "owned by"],
                    AUTHORIZER: ["module", "document type", "status", "amount range", "level 1", "level 2", "level 3", "level 4", "level 5"],
                    TAXES: ["id", "type", "code", "description", "rate", "computation", "created at", "updated at"],
                    DISCOUNTS: ["id", "source", "type", "description", "created by", "rate", "purchase quantity", "freebie quantity", "freebie item", "start date", "expiry date"],
                    UNIT_OF_MEASURE: ["id", "uom conversion code", "uom conversion description", "quantity", "uom", "quantity", "uom", "created at", "updated at"],
                    LOCATION: ["id", "parent location code", "location group", "location code", "location description", "created at", "updated at"],
                    ITEM_CLASS: ["id", "item class description", "assigned profit cost/center/s", "for delivery receipt", "created at", "updated at"],
                    ITEM: ["id", "item code", "item description", "item class", "uom", "item costing", "inventory source", "model / part number", "custom barcode number", "gl account code", "gl account description", "reorder point", "has expiry", "with components", "barcode enabled", "qr code enabled", "with serial number", "created at", "updated at"],
                    FINISHED_GOOD_COMPONENT: ["id", "item code", "item description", "item class", "yield quantity", "yield uom", "component item code", "component item description", "quantity", "component uom"],
                    MANAGE_BARCODE_QR_CODE: ["id", "item code", "item description", "type", "display field", "display value", "quantity to print"],
                    ITEM_LOCATION_TRANSFER: ["id", "document number", "document date", "approval status", "reference number", "transaction description", "p/c center code | name", "p/c center type"],
                    STOCK_REQUEST: ["id", "document number", "document date", "approval status", "reference number", "requesting unit (code)", "requesting unit (name)", "requesting unit (type)", "transaction description"],
                    STOCK_TRANSFER: ["id", "document number", "document date", "approval status", "reference number", "transaction description", "transfer from (profit/cost center name)", "transfer from (profit/cost center type)", "transfer to (profit/cost center name)", "transfer to (profit/cost center type)"],
                    DOCUMENT: ["id", "document type", "document number", "document date", "source", "approval status", "reference number", "transaction description", "owned by", "created at"],
                    ORDER: ["order number", "type", "profit / cost center", "status", "customer name", "pick-up address", "pick-up date", "delivery address", "delivery date", "appointment address", "appointment date", "contact person", "rating (stars)"],
                    STOCK_ACCEPTANCE: ["id", "document number", "document date", "approval status", "reference number", "source", "stock issuance source", "transaction description", "profit cost center code", "profit cost center type"],
                    IMPORTED_ITEMS_RECEIPT: ["id", "document number", "document date", "approval status", "reference number", "source", "receiving unit", "profit cost center type", "shipment cost per invoice", "customs duties", "value added tax", "brokerage fee", "freight and insurance", "wharfage and arrastre", "documentary stamps", "demurrage and storage", "other charges", "total import cost", "total inventory_cost", "transaction description"],
                    STOCK_ADJUSTMENT: ["id", "document number", "document date", "approval status", "reference number", "transaction description", "profit cost center name", "profit cost center type", "created at", "updated at"],
                    STOCK_ISSUANCE: ["id", "document number", "document date", "approval status", "reference number", "transaction source", "finished goods to produce", "transaction description", "transfer from (profit/cost center name)", "transfer from (type)", "transfer to (code)", "transfer to (name)"],
                    FINISHED_GOOD_PRODUCED: ["id", "document number", "document date", "approval status", "reference number", "finished goods to produce", "transaction description", "created at", "updated at"],
                    BEGINNING_BALANCE: ["id", "document number", "posting date", "approval status", "reference number", "p/c center code | name", "transaction description"],
                    STOCK_COUNT: ["id", "document number", "posting date", "approval status", "reference number", "transaction description", "created at", "updated at"],
                    ITEM_HISTORY: ["item code", "item description", "item class", "document date", "document number", "document type", "profit/cost center", "location", "uom", "quantity"],
                    SALES: ["doc. number", "branch", "customer", "customer discount", "payment status", "document date", "amount due", "amount received", "amount change", "item", "service", "quantity", "price", "amount"],
                    SALES_SUMMARIZED: ["doc. number", "branch", "customer", "customer discount", "payment status", "payment method", "document date", "amount due", "amount received", "amount change"],
                    CASH_REMITTANCE: ["date", "branch", "pos terminal", "amount", "status", "created by"],
                    ZREADING: ["doc. number", "branch", "customer", "customer discount", "payment status", "payment method", "document date", "amount due", "amount received", "amount change"],
                    END_OF_DAY: ["doc. number", "branch", "customer", "customer discount", "payment status", "payment method", "document date", "amount due", "amount received", "amount change"],
                    DELIVERY_RECEIPT: ["document number", "document date", "approval status", "reference number", "transaction source", "transaction description", "sold by (profit/cost center name)", "sold by (profit/cost center type)", "ship to", "total amount"],
                    DELIVERY_RECEIPT_DETAILED: ["document number", "document date", "approval status", "reference number", "transaction source", "transaction description", "sold by (profit/cost center name)", "sold by (profit/cost center type)", "ship to", "transaction amount", "item code", "item description", "uom", "quantity", "unit cost", "amount", "location"],
                    ISSUED_ITEMS_FOR_ACCEPTANCE: ["document number", "document date", "approval status", "reference number", "transaction source", "transaction description", "transfer from (profit/cost center name)", "transfer from (profit/cost center type)", "transfer to (profit/cost center name)", "transfer to (profit/cost center type)", "transaction amount", "item code", "item description", "uom", "issued quantity", "accepted quantity", "cancelled quantity", "unit cost", "amount", "location"],
                    STOCK_MOVEMENT: ["item code", "item description", "item class", "document date", "document number", "document type", "profit/cost center", "location", "uom", "quantity", "unit cost", "amount"],
                    STOCK_ON_HAND: ["item code", "item description", "item class", "profit/cost center", "location", "uom", "quantity", "amount", "average unit cost"],
                    ITEM_LEDGER: ["item code", "item description", "item class", "document date", "document number", "description", "profit/cost center", "location", "uom", "quantity", "stock quantity", "unit cost", "amount"],
                    INVENTORY_ITEM_BY_STATUS: ["item code", "item description", "item class", "uom", "on hand quantity", "on hand amount", "reorder point", "item status", "item turnover", "ranking"],
                    ITEM_CONDITIONS: ["item code", "item description", "item class", "serial number", "expiry date", "uom", "quantity", "item condition", "location"],
                    ORDERING_AND_APPOINTMENT_APP: ["id", "customer email", "first name", "last name", "mobile number", "created at", "updated at"],
                    MANAGE_CASHIER_SHIFTS: ["id", "day", "first name", "last name", "shift start", "shift end", "created at", "updated at"],
                },
                import_alignment: {
                    USER: ["left", "left", "left", "left", "left", "left"],
                    PROFIT_COST_CENTER: ["left", "left", "left"],
                    PERMISSION: ["left", "left", "left", "left", "left", "left", "left"],
                    AUTHORIZER: ["left", "left", "left", "left", "left", "left", "left"],
                    MAKER: ["left", "left", "left", "left", "left", "left"],
                    TAXES: ["left", "left", "left", "right", "left"],
                    DISCOUNTS: ["left", "left", "left", "right", "right", "right", "left", "left", "left"],
                    UNIT_OF_MEASURE: ["left", "left", "right", "left", "right", "left"],
                    LOCATION: ["left", "left", "left", "left"],
                    ITEM_CLASS: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    ITEM: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    FINISHED_GOOD_COMPONENT: ["left", "left", "left", "left", "left", "left"],
                    MANAGE_BARCODE_QR_CODE: ["left","left","left","left","left"],
                    ITEM_LOCATION_TRANSFER: ["left", "left", "left", "left", "right", "left", "left", "left"],
                    STOCK_REQUEST: ["left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_TRANSFER: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_ACCEPTANCE: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    IMPORTED_ITEMS_RECEIPT: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_ADJUSTMENT: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_ISSUANCE: ["left", "left", "left", "left", "left", "right", "left", "left"],
                    STOCK_ISSUANCE_DIRECT: ["left", "left", "left", "left", "left", "left", "right", "left", "left"],
                    FINISHED_GOOD_PRODUCED: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    BEGINNING_BALANCE: ["left", "left", "left", "right", "right", "left", "left", "left", "left", "left"],
                    STOCK_COUNT: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                    STOCK_COUNT_IMPORT_SHEET: ["left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left", "left"],
                },
                import_header: {
                    USER: ["email", "first name", "last name", "position", "role/s"],
                    PROFIT_COST_CENTER: ["profit/cost center code", "profit/cost center description", "assigned user/s (email address)"],
                    PERMISSION: ["id", "email", "name", "first name", "last name", "account status", "login status", "last logged out at"],
                    AUTHORIZER: ["document type", "amount range", "level 1", "level 2", "level 3", "level 4", "level 5"],
                    MAKER: ["id", "document type", "maker", "created at", "updated at"],
                    TAXES: ["type", "code", "description", "rate", "computation"],
                    DISCOUNTS: ["source", "type", "description", "rate", "purchase quantity", "freebie quantity", "freebie item code", "start date", "expiry date"],
                    UNIT_OF_MEASURE: ["uom conversion code", "uom conversion description", "quantity", "uom", "quantity", "uom"],
                    LOCATION: ["location code", "location description", "parent location code", "location group"],
                    ITEM_CLASS: ["item class description", "profit/cost center code/s", "for delivery receipt"],
                    ITEM: ["item code", "item description", "item class", "uom", "sales tax code", "item costing", "inventory source", "model / part number", "custom barcode number", "gl account code", "gl account description", "reorder point", "uom conversion code/s", "has expiry", "with components", "barcode enabled", "qr code enabled","with serial number","serial number type","serial number format","serial number series (no. of digits)"],
                    FINISHED_GOOD_COMPONENT: ["finished good item code","yield quantity","yield uom","component item code","component quantity","component uom"],
                    MANAGE_BARCODE_QR_CODE: ["item code","type","display field","display value","quantity to print"],
                    ITEM_LOCATION_TRANSFER: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "item code", "quantity", "uom", "from location code", "to location code"],
                    STOCK_REQUEST: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "requesting unit (profit/cost center code)", "profit/cost center type", "item code", "quantity", "uom"],
                    STOCK_TRANSFER: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "transfer from (profit/cost center code)", "transfer from (profit/cost center type)", "transfer to (profit/cost center code)", "transfer to (profit/cost center type)", "item code", "quantity", "uom"],
                    STOCK_ACCEPTANCE: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "requesting unit (profit/cost center code)", "profit cost center type", "item code", "quantity", "unit cost", "uom", "to location code", "serial number","expiry date"],
                    IMPORTED_ITEMS_RECEIPT: ["document date (dd/mm/yyyy)", "reference number", "source", "receiving unit (profit/cost center code)", "profit cost center type", "transaction description", "value added tax", "brokerage fee", "freight and insurance", "wharfage and arrastre", "documentary stamps", "demurrage and storage", "other charges", "item code", "quantity", "unit cost", "uom", "to location code"],
                    STOCK_ADJUSTMENT: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "profit/cost center code", "profit cost center type", "item code", "sign (+ or -)", "quantity", "unit cost", "uom", "location code", "serial number","expiry date"],
                    STOCK_ISSUANCE: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "ship to", "item code", "quantity", "uom", "from location code"],
                    STOCK_ISSUANCE_DIRECT: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "transfer to (profit cost center code)", "transfer to (profit cost center type)", "item code", "quantity", "uom", "from location code"],
                    FINISHED_GOOD_PRODUCED: ["document date (dd/mm/yyyy)", "reference number", "transaction description", "sold by (profit/cost center code)", "sold by (profit/cost center type)", "ship to", "item code", "quantity", "uom", "from location code"],
                    BEGINNING_BALANCE: ["reference number", "transaction description", "item code", "quantity", "unit cost", "uom", "location code", "expiry date (mm/dd/yyyy)", "serial number", "item condition"],
                    STOCK_COUNT: ["reference number", "transaction description", "item code", "quantity", "unit cost", "uom", "profit/cost center code", "profit/cost center type", "location code"],
                    STOCK_COUNT_SHEET: ["item code", "item description", "location", "serial number", "expiry date", "item condition", "uom", "per ledger qty", "unit cost","amount","actual count","variance qty","variance amount"],
                    STOCK_COUNT_IMPORT_SHEET: ["document date", "item code", "item description", "location code", "location description", "serial number", "expiry date", "item condition", "uom","actual count"],
                },
                import_comment: {
                    USER: [null, null, null, "Indicate the position such as follows:\n\t1. Manager\n\t2. Cashier\n", "1. Indicate role/s (System Admin; Accountant; Authorizer; Maker). \n2. If this user has multiple role/s, separate each role with a semicolon (;)."],
                    PROFIT_COST_CENTER: [null, null, "1. Indicate email address/es of user who have access to this profit cost center. \n2. If this profit cost center have multiple email address/es, separate each email address with a semicolon (;)."],
                    PERMISSION: [null, null, null, null, null, null, null],
                    AUTHORIZER: [null, null, null, null, null, null, null, null],
                    MAKER: [null, null, null, null, null],
                    TAXES: ["Indicate an appropriate type such as follows:\n\t1. Input Tax / Purchases\n\t2. Output Tax / Sales", null, null, null, "Indicate an appropriate computation such as follows:\n\t1. Tax Inclusive\n\t2. Tax Exclusive"],
                    DISCOUNTS: ["Indicate an appropriate source such as follows:\n\t1. Purchases\n\t2. Sales", "Indicate an appropriate type such as follows:\n\t1. Regular\n\t2. Promo", null, "Enter the rate of the discount. Applicable only to discount type 'Regular'", "Enter the purchase quantity required to get freebie/s. Applicable only to discount type 'Promo'", "Enter the freebie quantity for every purchased quantity. Applicable only to discount type 'Promo'", "Enter the item code of the freebie that the customer will get. Applicable only to discount type 'Promo'", "Enter the start date of the discount to take effect. Date must be in mm/dd/yyyy format.", "Enter the expiry date of the discount. Date must be in mm/dd/yyyy format."],
                    UNIT_OF_MEASURE: [null, null, null, null, null, null],
                    LOCATION: [null, null, null, "Select an appropriate location group from the available options.", null, null, null],
                    ITEM_CLASS: [null, "1. Indicate profit cost center code/s. \n2. If this item class have multiple profit cost center codes, separate each code with a semicolon (;).", "Indicate \"yes\" if item class is for delivery receipt. \"no\" if there's none" ],
                    ITEM: [
                        null, null,
                        "Indicate an appropriate item class such as follows:\n\t1. Finished Goods\n\t2. Semi-Finished Goods\n\t3. Raw Materials\n\t4. Packaging Materials\n\t5. Office Supplies\nNote: Please refer to the complete list from the dropdown box (Read-only Spreadsheet Mode).", 
                        null,
                        null,
                        "Indicate an appropriate item costing such as follows:\n\t1. Average\n\t2. FIFO\n\t3. LIFO",
                        "Indicate an appropriate inventory source such as follows:\n\t1. Imported\n\t2. Local Purchase\n\t3. Own Production", null, null, null, null,
                        null,
                        "1. Indicate uom conversion code/s found in the Unit of Measure Conversion maintenance. \n2. If this item has multiple uom conversion code/s, separate each code with a semicolon (;).\nNote: Please refer to the uom conversion that you have created/ setup previously.",
                        "Indicate \"yes\" if inventory item has expiry. \"no\" if there's none",
                        "Indicate \"yes\" if inventory item is with components. \"no\" if there's none",
                        "Indicate \"yes\" if barcode is enabled for this item. \"no\" if disabled",
                        "Indicate \"yes\" if qr code is enabled for this item. \"no\" if disabled",
                        "Indicate \"yes\" if this item is with serial number. \"no\" if disabled",
                        "Indicate an appropriate serial number type such as follows:\n\t1. In-house\n\t2. External\nNote: This is only applicable on items with serial number.",
                        "Indicate an appropriate serial number format such as follows:\n\t1. Item Code + Series\n\t2. Model/Part Number + Series\nNote: This is only applicable on items with serial number and with serial number type \"In-house\". Please refer to the complete list from the dropdown box (Read-only Spreadsheet Mode).",
                        "Indicate the number of digits of serial number series. For example (SN00031), the number of digits is 5."
                    ],
                    FINISHED_GOOD_COMPONENT: [null, null, null, null, null, null],
                    MANAGE_BARCODE_QR_CODE: [null, "Indicate an appropriate type such as follows:\n\t1. Barcode\n\t2. QR Code\nNote: Please refer to the complete list from the dropdown box (Read-only Spreadsheet Mode).", "Indicate an appropriate display field such as follows:\n\t1. Item Code\n\t2. Model / Part Number\n\t3. Serial Number\n\t4. Custom Barcode Number\n\t5. Manual Reprinting\nNote: Please refer to the complete list from the dropdown box (Read-only Spreadsheet Mode).", "Indicate the text that the barcode / qr code will represent. This column is only used for the display field \"Manual Reprinting\".", null],
                    ITEM_LOCATION_TRANSFER: [null, null, null, null, null, null, null, null],
                    STOCK_REQUEST: [null, null, null, null, null, null, null, null],
                    STOCK_TRANSFER: [null, null, null, null, null, null, null, null, null],
                    STOCK_ACCEPTANCE: [null, null, null, null, null, null, null, null, null, null, null, null, null],
                    IMPORTED_ITEMS_RECEIPT: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
                    STOCK_ADJUSTMENT: [null, null, null, null, null, null, null, null, null, null, null, null, null],
                    STOCK_ISSUANCE: [null, null, null, null, null, null, null, null],
                    STOCK_ISSUANCE_DIRECT: [null, null, null, null, null, null, null, null, null],
                    FINISHED_GOOD_PRODUCED: [null, null, null, null, null, null, null, null, null, null],
                    BEGINNING_BALANCE: [null, null, null, null, null, null, null, null, null, "Indicate an appropriate item condition such as follows:\n\t1. Good\n\t2. Damaged\n\t3. Bad\n\t4. Expired\n\t5. Near Expiry\n\t6. Obsolete\n\t7. Provision for Write-off"],
                    STOCK_COUNT: [null, null, null, null, null, null, null, null],
                    STOCK_COUNT_IMPORT_SHEET: [null, null, null, null, null, null, null, null, null],
                },
                file_name: {
                    MANAGE_SUBSCRIPTIONS: "Subscriptions",
                    PROFIT_COST_CENTER: "Profit Cost Centers",
                    USER: "Users",
                    PERMISSION: "User Permissions",
                    MANAGE_TRANSACTIONS: "Manage Users Transactions",
                    AUTHORIZER: "Authorizers",
                    TAXES: "Taxes",
                    DISCOUNTS: "Discounts",
                    MAKER: "Makers",
                    LOCATION: "Locations",
                    UNIT_OF_MEASURE: "Unit of Measure Conversions",
                    ITEM_CLASS: "Item Classes",
                    ITEM: "Items",
                    FINISHED_GOOD_COMPONENT: "Finished Goods Components",
                    BARCODE: "Barcode",
                    QR_CODE: "QR Code",
                    ITEM_LOCATION_TRANSFER: "Item Location Transfer",
                    STOCK_REQUEST: "Stock Purchase Request",
                    STOCK_TRANSFER: "Stock Transfer Request",
                    STOCK_ACCEPTANCE: "Stock Acceptance",
                    IMPORTED_ITEMS_RECEIPT: "Imported Items Receipt",
                    STOCK_ADJUSTMENT: "Stock Adjustment",
                    STOCK_ISSUANCE: "Stock Issuance",
                    FINISHED_GOOD_PRODUCED: "Finished Good Produced",
                    BEGINNING_BALANCE: "Beginning Balance",
                    STOCK_COUNT: "Stock Count",
                    ITEM_HISTORY: "Item History",
                    DELIVERY_RECEIPT: "Delivery Receipt",
                    ISSUED_ITEMS_FOR_ACCEPTANCE: "Issued Items for Acceptance",
                    STOCK_MOVEMENT: "Stock Movement",
                    STOCK_ON_HAND: "Stock on Hand",
                    INVENTORY_ITEM_BY_STATUS: "Inventory Items by Status",
                    ITEM_CONDITIONS: "Item Conditions",
                    ORDERING_AND_APPOINTMENT_APP: "Ordering and Appointment App - Customers",
                    SALES: "Sales",
                    CASH_REMITTANCE: "Cash Remittance",
                    ZREADING: "Z-Reading",
                    END_OF_DAY: "End of Day",
                },
            },
        },
        cn: {
            button: {
                LOGIN: "登录",
            },
        },
    },
};
