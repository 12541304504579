import { api } from "../../api/index";

export function detachAttachment(transaction = {}) {
    let url = `/api/documents/${transaction.id}/attachments/detach`;
    return new Promise((resolve, reject) =>
        api
        .post(url, transaction)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function postComment(transaction = {}) {
    let url = `/api/documents/${transaction.id}/comments/post`;
    return new Promise((resolve, reject) =>
        api
        .post(url, transaction)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function sendMessage(message = {}) {
    let url = `/api/ordering-and-appointment-app/send-message`;
    return new Promise((resolve, reject) =>
        api
        .post(url, message)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createImportedItemsReceipt(importedItemsReceipt = {}) {
    const formData = new FormData();
    formData.append("document_date", importedItemsReceipt.document_date);
    formData.append("reference_number", importedItemsReceipt.reference_number);
    formData.append("transaction_source", importedItemsReceipt.transaction_source ? importedItemsReceipt.transaction_source.value : "");
    formData.append("shipment_cost_per_invoice", importedItemsReceipt.shipment_cost_per_invoice);
    formData.append("freight_and_insurance", importedItemsReceipt.freight_and_insurance);
    formData.append("customs_duties", importedItemsReceipt.customs_duties);
    formData.append("value_added_tax", importedItemsReceipt.value_added_tax);
    formData.append("documentary_stamps", importedItemsReceipt.documentary_stamps);
    formData.append("demurrage_and_storage", importedItemsReceipt.demurrage_and_storage);
    formData.append("brokerage_fee", importedItemsReceipt.brokerage_fee);
    formData.append("wharfage_and_arrastre", importedItemsReceipt.wharfage_and_arrastre);
    formData.append("other_charges", importedItemsReceipt.other_charges);
    formData.append("transaction_description", importedItemsReceipt.transaction_description);
    formData.append("receiving_unit", importedItemsReceipt.transfer_to ? JSON.stringify(importedItemsReceipt.transfer_to) : '');
    for (let i=0; i<importedItemsReceipt.line_items.length; i++) {
        formData.append("item["+i+"]", importedItemsReceipt.line_items[i].item!==null ? importedItemsReceipt.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", importedItemsReceipt.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", importedItemsReceipt.line_items[i].unit_cost);
        formData.append("uom["+i+"]", importedItemsReceipt.line_items[i].uom!==null ? importedItemsReceipt.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", importedItemsReceipt.line_items[i].to_location!==null ? importedItemsReceipt.line_items[i].to_location.value : "");
    }
    if (importedItemsReceipt.attachments) {
        for (let i=0; i<importedItemsReceipt.attachments.length; i++) {
            formData.append("attachments["+i+"]", importedItemsReceipt.attachments[i]!==null ? importedItemsReceipt.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/imported-items-receipt", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateImportedItemsReceipt(importedItemsReceipt = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", importedItemsReceipt.document_date);
    formData.append("reference_number", importedItemsReceipt.reference_number);
    formData.append("transaction_source", importedItemsReceipt.transaction_source ? importedItemsReceipt.transaction_source.value : "");
    formData.append("shipment_cost_per_invoice", importedItemsReceipt.shipment_cost_per_invoice);
    formData.append("freight_and_insurance", importedItemsReceipt.freight_and_insurance);
    formData.append("customs_duties", importedItemsReceipt.customs_duties);
    formData.append("value_added_tax", importedItemsReceipt.value_added_tax);
    formData.append("documentary_stamps", importedItemsReceipt.documentary_stamps);
    formData.append("demurrage_and_storage", importedItemsReceipt.demurrage_and_storage);
    formData.append("brokerage_fee", importedItemsReceipt.brokerage_fee);
    formData.append("wharfage_and_arrastre", importedItemsReceipt.wharfage_and_arrastre);
    formData.append("other_charges", importedItemsReceipt.other_charges);
    formData.append("transaction_description", importedItemsReceipt.transaction_description);
    formData.append("receiving_unit", importedItemsReceipt.transfer_to ? JSON.stringify(importedItemsReceipt.transfer_to) : '');
    for (let i=0; i<importedItemsReceipt.line_items.length; i++) {
        formData.append("item["+i+"]", importedItemsReceipt.line_items[i].item!==null ? importedItemsReceipt.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", importedItemsReceipt.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", importedItemsReceipt.line_items[i].unit_cost);
        formData.append("uom["+i+"]", importedItemsReceipt.line_items[i].uom!==null ? importedItemsReceipt.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", importedItemsReceipt.line_items[i].to_location!==null ? importedItemsReceipt.line_items[i].to_location.value : "");
    }
    if (importedItemsReceipt.attachments) {
        for (let i=0; i<importedItemsReceipt.attachments.length; i++) {
            formData.append("attachments["+i+"]", importedItemsReceipt.attachments[i]!==null ? importedItemsReceipt.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/imported-items-receipts/${importedItemsReceipt.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitImportedItemsReceipt(importedItemsReceipt = {}) {
    let formData = new FormData();
    formData.append("id", importedItemsReceipt.id);
    formData.append("document_date", importedItemsReceipt.document_date);
    formData.append("reference_number", importedItemsReceipt.reference_number);
    formData.append("transaction_source", importedItemsReceipt.transaction_source ? importedItemsReceipt.transaction_source.value : "");
    formData.append("shipment_cost_per_invoice", importedItemsReceipt.shipment_cost_per_invoice);
    formData.append("freight_and_insurance", importedItemsReceipt.freight_and_insurance);
    formData.append("customs_duties", importedItemsReceipt.customs_duties);
    formData.append("value_added_tax", importedItemsReceipt.value_added_tax);
    formData.append("documentary_stamps", importedItemsReceipt.documentary_stamps);
    formData.append("demurrage_and_storage", importedItemsReceipt.demurrage_and_storage);
    formData.append("brokerage_fee", importedItemsReceipt.brokerage_fee);
    formData.append("wharfage_and_arrastre", importedItemsReceipt.wharfage_and_arrastre);
    formData.append("other_charges", importedItemsReceipt.other_charges);
    formData.append("transaction_description", importedItemsReceipt.transaction_description);
    formData.append("receiving_unit", importedItemsReceipt.transfer_to ? JSON.stringify(importedItemsReceipt.transfer_to) : '');
    for (let i=0; i<importedItemsReceipt.line_items.length; i++) {
        formData.append("item["+i+"]", importedItemsReceipt.line_items[i].item!==null ? importedItemsReceipt.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", importedItemsReceipt.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", importedItemsReceipt.line_items[i].unit_cost);
        formData.append("uom["+i+"]", importedItemsReceipt.line_items[i].uom!==null ? importedItemsReceipt.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", importedItemsReceipt.line_items[i].to_location!==null ? importedItemsReceipt.line_items[i].to_location.value : "");
        for (let j=0; j<importedItemsReceipt.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", importedItemsReceipt.line_items[i].serial_numbers[j] ? importedItemsReceipt.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", importedItemsReceipt.line_items[i].item_conditions[j] ? importedItemsReceipt.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<importedItemsReceipt.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", importedItemsReceipt.line_items[i].expiry_dates[j] ? importedItemsReceipt.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", importedItemsReceipt.line_items[i].expiry_quantities[j] ? importedItemsReceipt.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", importedItemsReceipt.line_items[i].item_conditions[j] ? importedItemsReceipt.line_items[i].item_conditions[j] : "");
        }
    }
    if (importedItemsReceipt.attachments) {
        for (let i=0; i<importedItemsReceipt.attachments.length; i++) {
            formData.append("attachments["+i+"]", importedItemsReceipt.attachments[i]!==null ? importedItemsReceipt.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/imported-items-receipts/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importImportedItemsReceipts(importedItemsReceipts = {}) {
    let formData = new FormData();
    for (let i=0; i<importedItemsReceipts.length; i++) {
        formData.append("document_date[]", importedItemsReceipts[i][0]);
        formData.append("reference_number[]", importedItemsReceipts[i][1]);
        formData.append("source[]", importedItemsReceipts[i][2]);
        formData.append("receiving_unit_code[]", importedItemsReceipts[i][3]);
        formData.append("profit_cost_center_type[]", importedItemsReceipts[i][4]);
        formData.append("transaction_description[]", importedItemsReceipts[i][5]);
        formData.append("value_added_tax[]", importedItemsReceipts[i][6]);
        formData.append("brokerage_fee[]", importedItemsReceipts[i][7]);
        formData.append("freight_and_insurance[]", importedItemsReceipts[i][8]);
        formData.append("wharfage_and_arrastre[]", importedItemsReceipts[i][9]);
        formData.append("documentary_stamps[]", importedItemsReceipts[i][10]);
        formData.append("demurrage_and_storage[]", importedItemsReceipts[i][11]);
        formData.append("other_charges[]", importedItemsReceipts[i][12]);
        formData.append("item_code[]", importedItemsReceipts[i][13]);
        formData.append("quantity[]", importedItemsReceipts[i][14]);
        formData.append("unit_cost[]", importedItemsReceipts[i][15]);
        formData.append("uom[]", importedItemsReceipts[i][16]);
        formData.append("to_location_code[]", importedItemsReceipts[i][17]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/imported-items-receipts/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveImportedItemsReceipt(importedItemsReceipt = {}) {
    let formData = new FormData();
    formData.append("id", importedItemsReceipt.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/imported-items-receipts/${importedItemsReceipt.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectImportedItemsReceipt(importedItemsReceipt = {}) {
    let formData = new FormData();
    formData.append("id", importedItemsReceipt.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/imported-items-receipts/${importedItemsReceipt.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidImportedItemsReceipt(importedItemsReceipt = {}) {
    let formData = new FormData();
    formData.append("id", importedItemsReceipt.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/imported-items-receipts/${importedItemsReceipt.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function login(credentials) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/login", credentials)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function signup(account = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/signup", account)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function createSubscription(subscription = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/subscription", subscription)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function updateSubscription(subscription = {}) {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("expiry_date", subscription.expiry_date);
    formData.append("number_of_pos_terminal", subscription.number_of_pos_terminal);
    formData.append("pos", subscription.pos);
    formData.append("inventory", subscription.inventory);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/subscriptions/${subscription.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function forgotPassword(email = "") {
	return new Promise((resolve, reject) =>
		api
			.post("/api/forgot-password", {email:email})
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function resetPassword(credentials = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/reset-password", credentials)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function changePassword(credentials = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/change-password", credentials)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function validateVerificationCode(verification_code) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/verification-code/${verification_code}`)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function pendingVerification(verification_code) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/pending-verification/${verification_code}`)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function verify(credentials) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/verify/${credentials.verification_code}`, credentials)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function acceptInvitation(params) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/accept-invitation/${params.company_id}/${params.user_id}`)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function skipProcess(params) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/${params.application}/skip-process`)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function duplicateProfitCostCenters(profitCostCenters = {}) {
    const formData = new FormData();
    for (let i=0; i<profitCostCenters.length; i++) {
        formData.append("id["+i+"]", profitCostCenters[i].id);
        formData.append("type["+i+"]", profitCostCenters[i].type);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/profit-cost-center/duplicate", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function duplicateItemClasses(itemClasses = {}) {
    const formData = new FormData();
    for (let i=0; i<itemClasses.length; i++) {
        formData.append("id["+i+"]", itemClasses[i].id);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/item-class/duplicate", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function duplicateItems(items = {}) {
    const formData = new FormData();
    for (let i=0; i<items.length; i++) {
        formData.append("id["+i+"]", items[i].id);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/item/duplicate", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function transferDocuments(documents = {}) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/transfer-documents`, documents)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function logout() {
	return new Promise((resolve, reject) =>
		api
			.post("/api/logout")
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function switchCompany(user, company) {
	return new Promise((resolve, reject) =>
		api
			.post(`/api/users/${user.id}/company`, company)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function fetchCustomerProfitCostCenters(params = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/ordering-and-appointment-app/profit-cost-centers`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAccountingPeriods(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/accounting-periods`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchStockCountSchedules(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_count_policy', query.stock_count_policy);
    params.append('period_type', query.period_type);
    params.append('active_year', query.active_year);
    params.append('beginning_balance_date', query.beginning_balance_date);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-count-schedules`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchSerialNumbers(query = {}) {
    const params = new URLSearchParams();
    if (query.stock_adjustment_line_item_id) params.append('stock_adjustment_line_item_id', query.stock_adjustment_line_item_id);
    if (query.item_location_transfer_line_item_id) params.append('item_location_transfer_line_item_id', query.item_location_transfer_line_item_id);
    if (query.finished_good_produced_line_item_id) params.append('finished_good_produced_line_item_id', query.finished_good_produced_line_item_id);
    params.append('current_page', query.current_page);
    params.append('entries_per_page', query.entries_per_page);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/serial-numbers`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchExpiryDates(query = {}) {
    const params = new URLSearchParams();
    if (query.stock_adjustment_line_item_id) params.append('stock_adjustment_line_item_id', query.stock_adjustment_line_item_id);
    if (query.item_location_transfer_line_item_id) params.append('item_location_transfer_line_item_id', query.item_location_transfer_line_item_id);
    if (query.finished_good_produced_line_item_id) params.append('finished_good_produced_line_item_id', query.finished_good_produced_line_item_id);
    params.append('current_page', query.current_page);
    params.append('entries_per_page', query.entries_per_page);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/expiry-dates`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllCountries(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/countries`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchDocumentsCount(query = {}) {
    const params = new URLSearchParams();
    params.append('status', query.status ? query.status : '');
	return new Promise((resolve, reject) =>
		api
			.get(`/api/documents/count`, {
  				params: params
			})
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function fetchAllDocuments(query = {}) {
    const params = new URLSearchParams();
    params.append('status', query.status ? query.status : '');
    params.append('document_number', query.document_number ? query.document_number : '');
    params.append('document_type', query.document_type ? query.document_type : '');
    params.append('source', query.source ? query.source : '');
    params.append('user', query.user ? query.user : '');
    params.append('authorizer', query.authorizer ? query.authorizer : '');
    params.append('action', query.action ? query.action : '');
    params.append('approval_status', query.approval_status ? query.approval_status : '');
    params.append('from_document_date', query.from_document_date ? query.from_document_date : '');
    params.append('to_document_date', query.to_document_date ? query.to_document_date : '');
	return new Promise((resolve, reject) =>
		api
			.get(`/api/documents`, {
  				params: params
			})
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function fetchAllItemLocationTransferLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('item_location_transfer_id', query.item_location_transfer_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-location-transfer-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockAdjustmentLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_adjustment_id', query.stock_adjustment_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-adjustment-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockRequestLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_request_id', query.stock_request_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-request-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockCountLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_count_id', query.stock_count_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-count-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockTransferLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_transfer_id', query.stock_transfer_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-transfer-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockIssuanceLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_issuance_id', query.stock_issuance_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-issuance-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllFinishedGoodProducedLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('finished_good_produced_id', query.finished_good_produced_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/finished-good-produced-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockAcceptanceLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('stock_acceptance_id', query.stock_acceptance_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-acceptance-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllImportedItemsReceiptLineItems(query = {}) {
    const params = new URLSearchParams();
    params.append('imported_items_receipt_id', query.imported_items_receipt_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/imported-items-receipt-line-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllUnitOfMeasures(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/unit-of-measures`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllUnitOfMeasureConversions(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/unit-of-measure-conversions`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllItemClasses(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-classes`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchItem(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/items/${query.id}`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchDiscount(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/discounts/${query.id}`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchOrder(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/ordering-and-appointment-app/orders/${query.id}`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllItems(query = {}) {
    const params = new URLSearchParams();
    params.append('finished_goods', query.finished_goods ? query.finished_goods : '');
    params.append('is_pos_item', query.is_pos_item ? query.is_pos_item : '');
    return new Promise((resolve, reject) =>
        api
            .get(`/api/items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllFinishedGoodComponents(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/finished-good-components`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllLocations(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/locations`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllLocationTypes(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/location-types`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchLocationTree(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/location-tree`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllUsers(query = {}) {
    const params = new URLSearchParams();
    params.append("account_status", query.account_status ? query.account_status : "" );
    params.append("position", query.position ? query.position : "" );
    return new Promise((resolve, reject) =>
        api
            .get(`/api/users`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllCustomers(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/customers`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchUser(user = {}) {
    const params = new URLSearchParams();
    params.append("email", user.email);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/user`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllProfitCostCenters(query = {}) {
    const params = new URLSearchParams();
    params.append("type", query.type ? query.type : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/profit-cost-centers`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllOrders(query = {}) {
    const params = new URLSearchParams();
    params.append("order_type", query.order_type);
    params.append("status", query.status);
    params.append("region", query.region);
    params.append("province", query.province);
    params.append("city", query.city);
    params.append("barangay", query.barangay);
    params.append("from_date", query.from_date);
    params.append("to_date", query.to_date);
    params.append("from_delivery_date", query.from_delivery_date);
    params.append("to_delivery_date", query.to_delivery_date);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/ordering-and-appointment-app/order`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockRequests(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-requests`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllSubscriptions(query = {}) {
    const params = new URLSearchParams();
    params.append("all", query.all ? true : false);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/subscriptions`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchSubscription(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/subscriptions/${query.id}`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllCompanies(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/companies`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllAmountRanges(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/amount-ranges`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllMessages(query = {}) {
    const params = new URLSearchParams();
    params.append("label", query.label);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/messages`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchMessagesCount(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/messages/count`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockTransfers(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-transfers`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllFinishedGoodProduces(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/finished-good-produces`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockAcceptances(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-acceptances`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllImportedItemsReceipts(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/imported-items-receipts`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockIssuances(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-issuances`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllBeginningBalances(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/beginning-balances`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockAdjustments(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-adjustments`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllItemLocationTransfers(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-location-transfers`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAllStockCounts(query = {}) {
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-counts`, {
                params: query
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchSales(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('item', query.item);
    params.append('payment_status', query.payment_status);
    params.append('summarized', query.summarized);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/sales`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchEndOfDay(query = {}) {
    const params = new URLSearchParams();
    params.append('branch', query.branch);
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('year_to_date', query.year_to_date===true ? query.year_to_date : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/end-of-day`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchEndOfDayReport(query = {}) {
    const params = new URLSearchParams();
    params.append('branch', query.branch);
    params.append('transaction_date', query.transaction_date);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/end-of-day-report`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchItemHistories(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('item', query.item);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-histories`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchCashRemittances(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/cash-remittance-report`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchStockMovements(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('item', query.item);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-movements`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchFastMovingItems(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/fast-moving-items`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchOnHandInventory(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/on-hand-inventory`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchNearStockoutSkus(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/near-stockout-skus`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchZeroStockInventories(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/zero-stock-inventories`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchStockOnHand(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('item', query.item);
    params.append('location', query.location);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-on-hand`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchItemConditions(query = {}) {
    const params = new URLSearchParams();
    params.append('item_class', query.item_class);
    params.append('item_condition', query.item_condition);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-conditions`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchItemConditionsPercentage(query = {}) {
    const params = new URLSearchParams();
    params.append('item_class', query.item_class);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-conditions-percentage`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchInventoryValueByItemClass(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/inventory-value-by-item-class`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchFinishedGoodsSold(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/finished-goods-sold`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchOnHandFinishedGoods(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/on-hand-finished-goods`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchInventoryItemByStatus(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date ? query.from_date : "");
    params.append('to_date', query.to_date ? query.to_date : "");
    params.append('item', query.item);
    params.append('status', query.status);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/inventory-item-by-status`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchItemLedger(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
	params.append('item', query.item);
    params.append('location', query.location);
    params.append('location_id', query.location_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/item-ledger`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchDeliveryReceipt(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('format', query.format);
    params.append('transaction_source', query.transaction_source);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/delivery-receipt`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchIssuedItemsForAcceptance(query = {}) {
    const params = new URLSearchParams();
    params.append('from_date', query.from_date);
    params.append('to_date', query.to_date);
    params.append('transaction_source', query.transaction_source);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/issued-items-for-acceptance`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchEndingInventories(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/ending-inventories`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchStockCountSheet(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/stock-count-sheet`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchAverageCostMethod(averageCostMethod = {}) {
    const params = new URLSearchParams();
    params.append('item_id', averageCostMethod.item_id);
    params.append('location_id', averageCostMethod.location_id);
    params.append('document_date', averageCostMethod.document_date);
    params.append('unit_of_measure_id', averageCostMethod.unit_of_measure_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/average-cost-method`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchUserCompanies(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/user-companies`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchCashierShifts(query = {}) {
    const params = new URLSearchParams();
    params.append("day", query.day ? query.day : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/cashier-shifts`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchLaundryMachineSets(query = {}) {
    const params = new URLSearchParams();
    params.append("branch", query.branch ? query.branch : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/laundry-machine-sets`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchPosTerminals(query = {}) {
    const params = new URLSearchParams();
    params.append("branch", query.branch ? query.branch : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/pos-terminals`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function fetchPosServices(query = {}) {
    const params = new URLSearchParams();
    return new Promise((resolve, reject) =>
        api
            .get(`/api/pos-items-and-services/services`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function createUser(user = {}) {
    const formData = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        position: user.position,
        selected_roles: user.selected_roles,
        selected_departments: user.selected_departments,
        selected_branches: user.selected_branches,
        selected_projects: user.selected_projects,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/user", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateUser(user = {}) {
    const formData = {
        first_name: user.first_name,
        last_name: user.last_name,
        position: user.position,
        selected_roles: user.selected_roles,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/users/${user.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateOrder(order = {}) {
    const formData = {
        status: order.status,
        profit_cost_center: order.profit_cost_center,
        status_message: order.status_message,
        pickup_date: order.pickup_date,
        delivery_date: order.delivery_date,
        appointment_date: order.appointment_date,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/ordering-and-appointment-app/orders/${order.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteUser(user = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/users/${user.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteUsers(users = {}) {
    let formData = new FormData();
    for (let i=0; i<users.length; i++) {
        formData.append("ids[]", users[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/users/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importUsers(users = {}) {
    let formData = new FormData();
    for (let i=0; i<users.length; i++) {
        formData.append("email[]", users[i][0]);
        formData.append("first_name[]", users[i][1]);
        formData.append("last_name[]", users[i][2]);
        formData.append("position[]", users[i][3]);
        formData.append("role[]", users[i][4]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/users", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createBranch(branch = {}) {
    const formData = {
        profit_cost_center_type: branch.type,
        profit_cost_center_code: branch.code,
        profit_cost_center_description: branch.description,
        selected_users: branch.selected_users,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/branch", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateBranch(branch = {}) {
    const formData = {
        profit_cost_center_type: branch.type,
        profit_cost_center_code: branch.code,
        profit_cost_center_description: branch.description,
        selected_users: branch.selected_users,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/branches/${branch.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createDepartment(department = {}) {
    const formData = {
        id: department.id,
        profit_cost_center_type: department.type,
        profit_cost_center_code: department.code,
        profit_cost_center_description: department.description,
        selected_users: department.selected_users,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/department", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateDepartment(department = {}) {
    const formData = {
        profit_cost_center_type: department.type,
        profit_cost_center_code: department.code,
        profit_cost_center_description: department.description,
        selected_users: department.selected_users,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/departments/${department.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateProject(project = {}) {
    const formData = {
        profit_cost_center_type: project.type,
        profit_cost_center_code: project.code,
        profit_cost_center_description: project.description,
        selected_users: project.selected_users,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/projects/${project.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createProject(project = {}) {
    const formData = {
        id: project.id,
        profit_cost_center_type: project.type,
        profit_cost_center_code: project.code,
        profit_cost_center_description: project.description,
        selected_users: project.selected_users,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/project", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function payOrderingAndAppointment(app = {}) {
    const formData = {
        id: app.id,
        amount_due: app.amount_due,
        payment_method: app.payment_method,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/ordering-and-appointment-app/payment", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function assignOrderingAndAppointmentAppUsers(app = {}) {
    const formData = {
        assigned_users: app.assigned_users,
        assigned_monitor_users: app.assigned_monitor_users,
        auto_approve_message: app.auto_approve_message,
        auto_approve_settings: app.auto_approve_settings,
        auto_approve_customers: app.auto_approve_customers,
        profit_cost_center_type: app.profit_cost_center_type,
        loyalty_reward_system: app.loyalty_reward_system,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/ordering-and-appointment-app/assign", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteBranch(branch = {}) {
    const formData = {
    };
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/branch/${branch.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteDepartment(department = {}) {
    const formData = {
    };
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/department/${department.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteProject(project = {}) {
    const formData = {
    };
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/project/${project.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importLocations(locations = {}) {
    const url = '/api/import-locations';
    const formData = new FormData();
    for (var i=0; i<locations.length; i++) {
        if (locations[i][0] || locations[i][1] || locations[i][2] || locations[i][3]) {
            formData.append("location_code[]", locations[i][0]);
            formData.append("location_description[]", locations[i][1]);
            formData.append("parent_location_code[]", locations[i][2]);
            formData.append("location_group[]", locations[i][3]);
        }
    }

    return new Promise((resolve, reject) =>
        api.post(url, formData).then((response) => resolve({
            data: response.data
        })).catch((error) => reject({
            data: error.response.data
        }))
    );
}

export function deleteProfitCostCenters(profitCostCenters = {}) {
    let formData = new FormData();
    for (let i=0; i<profitCostCenters.ids.length; i++) {
        formData.append("ids[]", profitCostCenters.ids[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/profit-cost-centers/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importProfitCostCenters(profitCostCenters = {}) {
    const url = '/api/profit-cost-center';
    const formData = new FormData();
    formData.append("profit_cost_center_type", profitCostCenters.type);
    for (var i=0; i<profitCostCenters.data.length; i++) {
        if (profitCostCenters.data[i][0] || profitCostCenters.data[i][1] || profitCostCenters.data[i][2]) {
            formData.append("profit_cost_center_code[]", profitCostCenters.data[i][0]);
            formData.append("profit_cost_center_description[]", profitCostCenters.data[i][1]);
            formData.append("assigned_users[]", profitCostCenters.data[i][2]);
        }
    }
    return new Promise((resolve, reject) =>
        api.post(url, formData).then((response) => resolve({
            data: response.data
        })).catch((error) => reject({
            data: error.response.data
        }))
    );
}

export function updatePermission(permission = {}) {
    const formData = {
        user: permission.user,
        permissions: permission.permissions,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/permission", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateMessageStatus(message = {}) {
    const formData = {
        id: message.id
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/messages/${message.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateMessagesStatus(params = {}) {
    const formData = {
        ids: params.ids
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/messages`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteMessages(params = {}) {
    const formData = {
        ids: params.ids
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/messages-delete`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function assignAuthorizers(authorizers = {}) {
    const formData = {
        approval_setup: authorizers.approval_setup,
        start_amount: authorizers.start_amount,
        end_amount: authorizers.end_amount,
        document_type: authorizers.document_type,
        level: authorizers.level,
        authorizers: authorizers.authorizers,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/authorizer", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function unAssignAuthorizers(authorizers = {}) {
    const formData = {
        document_type_filter: authorizers.document_type_filter,
        authorizers_to_unassign: authorizers.authorizers_to_unassign,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/authorizer/unassign", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteAmountRange(amountRange = {}) {
    const formData = {
        document_type: amountRange.document_type,
        amount: amountRange.amount,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/amount-range", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createMakers(makers = {}) {
    const formData = {
        document_type: makers.document_type,
        makers: makers.makers,
    };
    return new Promise((resolve, reject) =>
        api
        .post("/api/maker", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteMaker(maker = {}) {
    const formData = {
    };
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/maker/${maker.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createUnitOfMeasure(unitOfMeasure = {}) {
    return new Promise((resolve, reject) =>
        api
        .post("/api/unit-of-measure", unitOfMeasure)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createUnitOfMeasureConversion(unitOfMeasureConversion = {}) {
    return new Promise((resolve, reject) =>
        api
        .post("/api/unit-of-measure-conversion", unitOfMeasureConversion)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createItemClass(itemClass = {}) {
    return new Promise((resolve, reject) =>
        api
        .post("/api/item-class", itemClass)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateItemClass(itemClass = {}) {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("item_class_code", itemClass.item_class_code);
    formData.append("item_class_description", itemClass.item_class_description);
    formData.append("for_delivery_receipt", itemClass.for_delivery_receipt);
    for (let i=0; i<itemClass.selected_profit_cost_centers.length; i++) {
        formData.append("selected_profit_cost_centers["+i+"]", itemClass.selected_profit_cost_centers[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-classes/${itemClass.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createItem(item = {}) {
    const formData = new FormData();
    formData.append("item_code", item.item_code);
    formData.append("item_description", item.item_description);
    formData.append("item_class", item.item_class);
    formData.append("selling_price", item.selling_price);
    formData.append("tax", item.tax);
    formData.append("discount", item.discount);
    formData.append("unit_of_measure", item.unit_of_measure);
    formData.append("item_costing", item.item_costing);
    formData.append("inventory_source", item.inventory_source);
    formData.append("part_number", item.part_number);
    formData.append("reorder_point", item.reorder_point);
    formData.append("account_code", item.account_code);
    formData.append("account_description", item.account_description);
    formData.append("custom_barcode_number", item.custom_barcode_number);
    formData.append("inventory_account", item.inventory_account!==null ? item.inventory_account : "");
    formData.append("has_expiry", item.has_expiry);
    formData.append("is_enabled", item.is_enabled);
    formData.append("is_barcode_enabled", item.is_barcode_enabled);
    formData.append("is_qr_code_enabled", item.is_qr_code_enabled);
    formData.append("with_components", item.with_components);
    formData.append("with_serial_number", item.with_serial_number);
    formData.append("serial_number_type", item.serial_number_type);
    formData.append("serial_number_format", item.serial_number_format);
    formData.append("serial_number_series", item.serial_number_series);
    if (item.item_image!==null) {
        formData.append("item_image", item.item_image);
    }
    for (let i=0; i<item.selected_unit_of_measure_conversions.length; i++) {
        formData.append("selected_unit_of_measure_conversions["+i+"]", item.selected_unit_of_measure_conversions[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/item", formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateItem(item = {}) {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("item_code", item.item_code);
    formData.append("item_description", item.item_description);
    formData.append("item_class", item.item_class);
    formData.append("selling_price", item.selling_price);
    formData.append("tax", item.tax);
    formData.append("discount", item.discount);
    formData.append("unit_of_measure", item.unit_of_measure);
    formData.append("item_costing", item.item_costing);
    formData.append("inventory_source", item.inventory_source);
    formData.append("part_number", item.part_number);
    formData.append("reorder_point", item.reorder_point);
    formData.append("account_code", item.account_code);
    formData.append("account_description", item.account_description);
    formData.append("custom_barcode_number", item.custom_barcode_number);
    formData.append("inventory_account", item.inventory_account!==null ? item.inventory_account : "");
    formData.append("has_expiry", item.has_expiry);
    formData.append("is_enabled", item.is_enabled);
    formData.append("is_barcode_enabled", item.is_barcode_enabled);
    formData.append("is_qr_code_enabled", item.is_qr_code_enabled);
    formData.append("with_components", item.with_components);
    formData.append("with_serial_number", item.with_serial_number);
    formData.append("serial_number_type", item.serial_number_type);
    formData.append("serial_number_format", item.serial_number_format);
    formData.append("serial_number_series", item.serial_number_series);
    for (let i=0; i<item.selected_unit_of_measure_conversions.length; i++) {
        formData.append("selected_unit_of_measure_conversions["+i+"]", item.selected_unit_of_measure_conversions[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/items/${item.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importItems(items = {}) {
    const formData = new FormData();
    for (var i=0; i<items.length; i++) {
        if (items[i][0] || items[i][1] || items[i][2] || items[i][3] || items[i][4] || items[i][5] || items[i][6] || items[i][7] || items[i][8] || items[i][9] || items[i][10] || items[i][11] || items[i][12] || items[i][13] || items[i][14] || items[i][15] || items[i][16] || items[i][17] || items[i][18] || items[i][19] || items[i][20]) {
            formData.append("item_code[]", items[i][0]);
            formData.append("item_description[]", items[i][1]);
            formData.append("item_class[]", items[i][2]);
            formData.append("uom[]", items[i][3]);
            formData.append("sales_tax_code[]", items[i][4]);
            formData.append("item_costing[]", items[i][5]);
            formData.append("inventory_source[]", items[i][6]);
            formData.append("part_number[]", items[i][7]);
            formData.append("custom_barcode_number[]", items[i][8]);
            formData.append("gl_account_code[]", items[i][9]);
            formData.append("gl_account_description[]", items[i][10]);
            formData.append("reorder_point[]", items[i][11]);
            formData.append("uom_conversion_codes[]", items[i][12]===null ? "" : items[i][12]);
            formData.append("has_expiry[]", items[i][13]);
            formData.append("with_components[]", items[i][14]);
            formData.append("barcode_enabled[]", items[i][15]);
            formData.append("qr_code_enabled[]", items[i][16]);
            formData.append("with_serial_number[]", items[i][17]);
            formData.append("serial_number_type[]", items[i][18] ? items[i][18] : "");
            formData.append("serial_number_format[]", items[i][19] ? items[i][19] : "");
            formData.append("serial_number_series[]", items[i][20] ? items[i][20] : 0);
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/items", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteItems(items = {}) {
    const formData = new FormData();
    for (var i=0; i<items.ids.length; i++) {
        formData.append("ids[]", items.ids[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/items/delete-all", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteItem(item = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/items/${item.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteItemImage(item = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/images/${item.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateItemImage(item = {}) {
    const formData = new FormData();
    formData.append("url", item.url);
    formData.append("item_image", item.item_image);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/images/${item.id}`, formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateServiceImage(service = {}) {
    const formData = new FormData();
    formData.append("url", service.url);
    formData.append("service_image", service.service_image);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/services/images/${service.id}`, formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function uploadCompanyLogo(company = {}) {
    const formData = new FormData();
    formData.append("url", company.url);
    formData.append("logo", company.logo);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/company-logo`, formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createFinishedGoodComponents(item = {}) {
    const formData = new FormData();
    formData.append("finished_good_item", item.finished_good_item);
    formData.append("yield_quantity", item.yield_quantity);
    formData.append("yield_uom", item.yield_uom);
    for (let i=0; i<item.components.length; i++) {
        formData.append("component_item["+i+"]", item.components[i].item!==null ? item.components[i].item.value : "");
        formData.append("component_quantity["+i+"]", item.components[i].quantity);
        formData.append("component_uom["+i+"]", item.components[i].uom!==null ? item.components[i].uom.value : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/finished-good-component", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importFinishedGoodComponents(items = {}) {
    const formData = new FormData();
    for (var i=0; i<items.length; i++) {
        if (items[i][0] || items[i][1] || items[i][2] || items[i][3] || items[i][4] || items[i][5]) {
            formData.append("finished_good_item_code[]", items[i][0]===null ? "" : items[i][0]);
            formData.append("yield_quantity[]", items[i][1]===null ? "" : items[i][1]);
            formData.append("yield_uom[]", items[i][2]===null ? "" : items[i][2]);
            formData.append("component_item_code[]", items[i][3]===null ? "" : items[i][3]);
            formData.append("component_quantity[]", items[i][4]===null ? "" : items[i][4]);
            formData.append("component_uom[]", items[i][5]===null ? "" : items[i][5]);
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/finished-good-components", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteFinishedGoodComponents(finishedGoodComponents = {}) {
    let formData = new FormData();
    for (let i=0; i<finishedGoodComponents.length; i++) {
        formData.append("ids[]", finishedGoodComponents[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/finished-good-components/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createPrintJob(printJob = {}) {
    return new Promise((resolve, reject) =>
        api
        .post("/api/print-job", printJob)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deletePrintJob(printJob = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/print-jobs/${printJob.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importPrintJobs(printJobs = {}) {
    const formData = new FormData();
    for (var i=0; i<printJobs.length; i++) {
        if (printJobs[i][0] || printJobs[i][1] || printJobs[i][2] || printJobs[i][3] || printJobs[i][4]) {
            formData.append("item_code[]", printJobs[i][0]);
            formData.append("type[]", printJobs[i][1]);
            formData.append("display_field[]", printJobs[i][2]);
            formData.append("display_value[]", printJobs[i][3]);
            formData.append("quantity[]", printJobs[i][4]);
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/print-jobs", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateItemSerialNumberCount(item = {}) {
    let url = `/api/items/${item.id}/serial-number`;
    return new Promise((resolve, reject) =>
        api
        .post(url, item)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createLocationType(locationType = {}) {
    return new Promise((resolve, reject) =>
        api
        .post("/api/location-type", locationType)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createLocation(location = {}) {
    return new Promise((resolve, reject) =>
        api.post("/api/location", location).then((response) => resolve({
            data: response.data
        })).catch((error) => reject({
            data: error.response.data
        }))
    );
}

export function deleteLocation(location = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/locations/${location.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteLocations(locations = {}) {
    let formData = new FormData();
    for (let i=0; i<locations.length; i++) {
        formData.append("ids[]", locations[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/locations/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createStockRequest(stockRequest = {}) {
    const formData = new FormData();
    formData.append("document_date", stockRequest.document_date);
    formData.append("reference_number", stockRequest.reference_number);
    formData.append("requesting_unit", stockRequest.requesting_unit ? JSON.stringify(stockRequest.requesting_unit) : '');
    formData.append("transaction_description", stockRequest.transaction_description);
    for (let i=0; i<stockRequest.line_items.length; i++) {
        formData.append("item["+i+"]", stockRequest.line_items[i].item!==null ? stockRequest.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", stockRequest.line_items[i].quantity);
        formData.append("uom["+i+"]", stockRequest.line_items[i].uom!==null ? stockRequest.line_items[i].uom.value : "");
    }
    for (let i=0; i<stockRequest.attachments.length; i++) {
        formData.append("attachments["+i+"]", stockRequest.attachments[i]!==null ? stockRequest.attachments[i] : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/stock-request", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateStockRequest(stockRequest = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", stockRequest.document_date);
    formData.append("reference_number", stockRequest.reference_number);
    formData.append("requesting_unit", stockRequest.requesting_unit ? JSON.stringify(stockRequest.requesting_unit) : '');
    formData.append("transaction_description", stockRequest.transaction_description);
    for (let i=0; i<stockRequest.line_items.length; i++) {
        formData.append("item["+i+"]", stockRequest.line_items[i].item!==null ? stockRequest.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", stockRequest.line_items[i].quantity);
        formData.append("uom["+i+"]", stockRequest.line_items[i].uom!==null ? stockRequest.line_items[i].uom.value : "");
    }
    if (stockRequest.attachments) {
        for (let i=0; i<stockRequest.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockRequest.attachments[i]!==null ? stockRequest.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-requests/${stockRequest.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitDocuments(documents = {}) {
    let formData = new FormData();
    for (let i=0; i<documents.length; i++) {
        formData.append("id["+i+"]", documents[i].id!==null ? documents[i].id : "");
        formData.append("document_type["+i+"]", documents[i].document_type!==null ? documents[i].document_type : "");
        formData.append("source["+i+"]", documents[i].source!==null ? documents[i].source : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/documents/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitStockRequest(stockRequest = {}) {
    let formData = new FormData();
    formData.append("id", stockRequest.id);
    formData.append("document_date", stockRequest.document_date);
    formData.append("reference_number", stockRequest.reference_number);
    formData.append("requesting_unit", stockRequest.requesting_unit ? JSON.stringify(stockRequest.requesting_unit) : '');
    formData.append("transaction_description", stockRequest.transaction_description);
    for (let i=0; i<stockRequest.line_items.length; i++) {
        formData.append("item["+i+"]", stockRequest.line_items[i].item!==null ? stockRequest.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", stockRequest.line_items[i].quantity);
        formData.append("uom["+i+"]", stockRequest.line_items[i].uom!==null ? stockRequest.line_items[i].uom.value : "");
    }
    for (let i=0; i<stockRequest.attachments.length; i++) {
        formData.append("attachments["+i+"]", stockRequest.attachments[i]!==null ? stockRequest.attachments[i] : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-requests/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveStockRequest(stockRequest = {}) {
    let formData = new FormData();
    formData.append("id", stockRequest.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-requests/${stockRequest.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectStockRequest(stockRequest = {}) {
    let formData = new FormData();
    formData.append("id", stockRequest.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-requests/${stockRequest.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidStockRequest(stockRequest = {}) {
    let formData = new FormData();
    formData.append("id", stockRequest.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-requests/${stockRequest.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importItemClasses(itemClasses = {}) {
    const formData = new FormData();
    for (var i=0; i<itemClasses.length; i++) {
        if (itemClasses[i][0] || itemClasses[i][1] || itemClasses[i][2]) {
            formData.append("item_class_description[]", itemClasses[i][0]);
            formData.append("profit_cost_center_codes[]", itemClasses[i][1]);
            formData.append("for_delivery_receipt[]", itemClasses[i][2]);
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/item-classes-import", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importStockRequests(stockRequests = {}) {
    let formData = new FormData();
    for (let i=0; i<stockRequests.length; i++) {
        formData.append("document_date[]", stockRequests[i][0]);
        formData.append("reference_number[]", stockRequests[i][1]);
        formData.append("transaction_description[]", stockRequests[i][2]);
        formData.append("requesting_unit_code[]", stockRequests[i][3]);
        formData.append("profit_cost_center_type[]", stockRequests[i][4]);
        formData.append("item_code[]", stockRequests[i][5]);
        formData.append("quantity[]", stockRequests[i][6]);
        formData.append("uom[]", stockRequests[i][7]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-requests/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateStockCount(stockCount = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("reference_number", stockCount.reference_number);
    formData.append("transaction_description", stockCount.transaction_description);
    if (stockCount.attachments) {
        for (let i=0; i<stockCount.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockCount.attachments[i]!==null ? stockCount.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-counts/${stockCount.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitStockCount(stockCount = {}) {
    try {
        let formData = new FormData();
        formData.append("id", stockCount.id);
        formData.append("reference_number", stockCount.reference_number);
        formData.append("transaction_description", stockCount.transaction_description);
        if (stockCount.attachments) {
            for (let i=0; i<stockCount.attachments.length; i++) {
                formData.append("attachments["+i+"]", stockCount.attachments[i]!==null ? stockCount.attachments[i] : "");
            }
        }
        return new Promise((resolve, reject) =>
            api
            .post(`/api/stock-counts/submit`, formData)
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
        );
    }
    catch (e) {}
}

export function approveStockCount(stockCount = {}) {
    let formData = new FormData();
    formData.append("id", stockCount.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-counts/${stockCount.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectStockCount(stockCount = {}) {
    let formData = new FormData();
    formData.append("id", stockCount.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-counts/${stockCount.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidStockCount(stockCount = {}) {
    let formData = new FormData();
    formData.append("id", stockCount.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-counts/${stockCount.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importStockCounts(stockCounts = {}) {
    let formData = new FormData();
    for (let i=0; i<stockCounts.length; i++) {
        formData.append("item_code[]", stockCounts[i][1]);
        formData.append("location_code[]", stockCounts[i][3]);
        formData.append("serial_number[]", stockCounts[i][5]);
        formData.append("expiry_date[]", stockCounts[i][6]);
        formData.append("item_condition[]", stockCounts[i][7]);
        formData.append("uom[]", stockCounts[i][8]);
        formData.append("actual_count[]", stockCounts[i][9]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-counts/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createStockTransfer(stockTransfer = {}) {
    const formData = new FormData();
    formData.append("document_date", stockTransfer.document_date);
    formData.append("reference_number", stockTransfer.reference_number);
    formData.append("transaction_description", stockTransfer.transaction_description);
    formData.append("transfer_from", JSON.stringify(stockTransfer.transfer_from));
    formData.append("transfer_to", JSON.stringify(stockTransfer.transfer_to));
    for (let i=0; i<stockTransfer.line_items.length; i++) {
        formData.append("item["+i+"]", stockTransfer.line_items[i].item!==null ? stockTransfer.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", stockTransfer.line_items[i].quantity);
        formData.append("uom["+i+"]", stockTransfer.line_items[i].uom!==null ? stockTransfer.line_items[i].uom.value : "");
    }
    for (let i=0; i<stockTransfer.attachments.length; i++) {
        formData.append("attachments["+i+"]", stockTransfer.attachments[i]!==null ? stockTransfer.attachments[i] : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/stock-transfer", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateStockTransfer(stockTransfer = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", stockTransfer.document_date);
    formData.append("reference_number", stockTransfer.reference_number);
    formData.append("transaction_description", stockTransfer.transaction_description);
    formData.append("transfer_from", JSON.stringify(stockTransfer.transfer_from));
    formData.append("transfer_to", JSON.stringify(stockTransfer.transfer_to));
    for (let i=0; i<stockTransfer.line_items.length; i++) {
        formData.append("item["+i+"]", stockTransfer.line_items[i].item!==null ? stockTransfer.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", stockTransfer.line_items[i].quantity);
        formData.append("uom["+i+"]", stockTransfer.line_items[i].uom!==null ? stockTransfer.line_items[i].uom.value : "");
    }
    if (stockTransfer.attachments) {
        for (let i=0; i<stockTransfer.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockTransfer.attachments[i]!==null ? stockTransfer.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-transfers/${stockTransfer.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitStockTransfer(stockTransfer = {}) {
    try {
        let formData = new FormData();
        formData.append("id", stockTransfer.id);
        formData.append("document_date", stockTransfer.document_date);
        formData.append("reference_number", stockTransfer.reference_number);
        formData.append("transfer_from", JSON.stringify(stockTransfer.transfer_from));
        formData.append("transfer_to", JSON.stringify(stockTransfer.transfer_to));
        formData.append("transaction_description", stockTransfer.transaction_description);
        for (let i=0; i<stockTransfer.line_items.length; i++) {
            formData.append("item["+i+"]", stockTransfer.line_items[i].item!==null ? stockTransfer.line_items[i].item.value : "");
            formData.append("quantity["+i+"]", stockTransfer.line_items[i].quantity);
            formData.append("uom["+i+"]", stockTransfer.line_items[i].uom!==null ? stockTransfer.line_items[i].uom.value : "");
        }
        if (stockTransfer.attachments) {
            for (let i=0; i<stockTransfer.attachments.length; i++) {
                formData.append("attachments["+i+"]", stockTransfer.attachments[i]!==null ? stockTransfer.attachments[i] : "");
            }
        }
        return new Promise((resolve, reject) =>
            api
            .post(`/api/stock-transfers/submit`, formData)
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
        );
    }
    catch (e) {}
}

export function rejectStockTransfer(stockTransfer = {}) {
    let formData = new FormData();
    formData.append("id", stockTransfer.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-transfers/${stockTransfer.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveStockTransfer(stockTransfer = {}) {
    let formData = new FormData();
    formData.append("id", stockTransfer.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-transfers/${stockTransfer.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidStockTransfer(stockTransfer = {}) {
    let formData = new FormData();
    formData.append("id", stockTransfer.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-transfers/${stockTransfer.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importStockTransfers(stockTransfers = {}) {
    let formData = new FormData();
    for (let i=0; i<stockTransfers.length; i++) {
        formData.append("document_date[]", stockTransfers[i][0]);
        formData.append("reference_number[]", stockTransfers[i][1]);
        formData.append("transaction_description[]", stockTransfers[i][2]);
        formData.append("transfer_from_code[]", stockTransfers[i][3]);
        formData.append("transfer_from_profit_cost_center_type[]", stockTransfers[i][4]);
        formData.append("transfer_to_code[]", stockTransfers[i][5]);
        formData.append("transfer_to_profit_cost_center_type[]", stockTransfers[i][6]);
        formData.append("item_code[]", stockTransfers[i][7]);
        formData.append("quantity[]", stockTransfers[i][8]);
        formData.append("uom[]", stockTransfers[i][9]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-transfers/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createStockIssuance(stockIssuance = {}) {
    const formData = new FormData();
    formData.append("document_date", stockIssuance.document_date);
    formData.append("reference_number", stockIssuance.reference_number);
    formData.append("transaction_source", stockIssuance.transaction_source ? stockIssuance.transaction_source.value : "");
    formData.append("finished_goods_to_produce", stockIssuance.finished_goods_to_produce ? stockIssuance.finished_goods_to_produce.value : "");
    formData.append("number_of_batches", stockIssuance.number_of_batches);
    formData.append("transaction_description", stockIssuance.transaction_description);
    formData.append("ship_to", stockIssuance.ship_to);
    formData.append("sold_by", stockIssuance.sold_by ? JSON.stringify(stockIssuance.sold_by) : "");
    formData.append("transfer_from", stockIssuance.transfer_from ? JSON.stringify(stockIssuance.transfer_from) : "");
    formData.append("transfer_to", stockIssuance.transfer_to ? JSON.stringify(stockIssuance.transfer_to) : "");
    for (let i=0; i<stockIssuance.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", stockIssuance.selected_document_ids[i]);
    }
    for (let i=0; i<stockIssuance.line_items.length; i++) {
        formData.append("item["+i+"]", stockIssuance.line_items[i].item!==null ? stockIssuance.line_items[i].item.value : "");
        formData.append("requested_quantity["+i+"]", stockIssuance.line_items[i].requested_quantity.length>0 ? stockIssuance.line_items[i].requested_quantity : 0);
        formData.append("balance_quantity["+i+"]", stockIssuance.line_items[i].balance_quantity.length>0 ? stockIssuance.line_items[i].balance_quantity : 0);
        formData.append("cancel_quantity["+i+"]", stockIssuance.line_items[i].cancel_quantity.length>0 ? stockIssuance.line_items[i].cancel_quantity : 0);
        formData.append("quantity["+i+"]", stockIssuance.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", stockIssuance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockIssuance.line_items[i].uom!==null ? stockIssuance.line_items[i].uom.value : "");
        formData.append("from_location["+i+"]", stockIssuance.line_items[i].from_location!==null ? stockIssuance.line_items[i].from_location.value : "");
    }
    if (stockIssuance.attachments) {
        for (let i=0; i<stockIssuance.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockIssuance.attachments[i]!==null ? stockIssuance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/stock-issuance", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateStockIssuance(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", stockIssuance.document_date);
    formData.append("reference_number", stockIssuance.reference_number);
    formData.append("transaction_source", stockIssuance.transaction_source ? stockIssuance.transaction_source.value : "");
    formData.append("finished_goods_to_produce", stockIssuance.finished_goods_to_produce ? stockIssuance.finished_goods_to_produce.value : "");
    formData.append("number_of_batches", stockIssuance.number_of_batches);
    formData.append("transaction_description", stockIssuance.transaction_description);
    formData.append("ship_to", stockIssuance.ship_to);
    formData.append("sold_by", stockIssuance.sold_by ? JSON.stringify(stockIssuance.sold_by) : "");
    formData.append("transfer_from", stockIssuance.transfer_from ? JSON.stringify(stockIssuance.transfer_from) : "");
    formData.append("transfer_to", stockIssuance.transfer_to ? JSON.stringify(stockIssuance.transfer_to) : "");
    for (let i=0; i<stockIssuance.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", stockIssuance.selected_document_ids[i]);
    }
    for (let i=0; i<stockIssuance.line_items.length; i++) {
        formData.append("item["+i+"]", stockIssuance.line_items[i].item!==null ? stockIssuance.line_items[i].item.value : "");
        formData.append("requested_quantity["+i+"]", stockIssuance.line_items[i].requested_quantity.length>0 ? stockIssuance.line_items[i].requested_quantity : 0);
        formData.append("balance_quantity["+i+"]", stockIssuance.line_items[i].balance_quantity.length>0 ? stockIssuance.line_items[i].balance_quantity : 0);
        formData.append("cancel_quantity["+i+"]", stockIssuance.line_items[i].cancel_quantity.length>0 ? stockIssuance.line_items[i].cancel_quantity : 0);
        formData.append("quantity["+i+"]", stockIssuance.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", stockIssuance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockIssuance.line_items[i].uom!==null ? stockIssuance.line_items[i].uom.value : "");
        formData.append("from_location["+i+"]", stockIssuance.line_items[i].from_location!==null ? stockIssuance.line_items[i].from_location.value : "");
        for (let j=0; j<stockIssuance.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", stockIssuance.line_items[i].serial_numbers[j] ? stockIssuance.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockIssuance.line_items[i].item_conditions[j] ? stockIssuance.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<stockIssuance.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", stockIssuance.line_items[i].expiry_dates[j] ? stockIssuance.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", stockIssuance.line_items[i].expiry_quantities[j] ? stockIssuance.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockIssuance.line_items[i].item_conditions[j] ? stockIssuance.line_items[i].item_conditions[j] : "");
        }
    }
    if (stockIssuance.attachments) {
        for (let i=0; i<stockIssuance.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockIssuance.attachments[i]!==null ? stockIssuance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitStockIssuance(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    formData.append("document_date", stockIssuance.document_date);
    formData.append("reference_number", stockIssuance.reference_number);
    formData.append("transaction_source", stockIssuance.transaction_source ? stockIssuance.transaction_source.value : "");
    formData.append("finished_goods_to_produce", stockIssuance.finished_goods_to_produce ? stockIssuance.finished_goods_to_produce.value : "");
    formData.append("number_of_batches", stockIssuance.number_of_batches);
    formData.append("transaction_description", stockIssuance.transaction_description);
    formData.append("ship_to", stockIssuance.ship_to);
    formData.append("sold_by", stockIssuance.sold_by ? JSON.stringify(stockIssuance.sold_by) : "");
    formData.append("transfer_from", stockIssuance.transfer_from ? JSON.stringify(stockIssuance.transfer_from) : "");
    formData.append("transfer_to", stockIssuance.transfer_to ? JSON.stringify(stockIssuance.transfer_to) : "");
    for (let i=0; i<stockIssuance.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", stockIssuance.selected_document_ids[i]);
    }
    for (let i=0; i<stockIssuance.line_items.length; i++) {
        formData.append("item["+i+"]", stockIssuance.line_items[i].item!==null ? stockIssuance.line_items[i].item.value : "");
        formData.append("requested_quantity["+i+"]", stockIssuance.line_items[i].requested_quantity.length>0 ? stockIssuance.line_items[i].requested_quantity : 0);
        formData.append("balance_quantity["+i+"]", stockIssuance.line_items[i].balance_quantity.length>0 ? stockIssuance.line_items[i].balance_quantity : 0);
        formData.append("cancel_quantity["+i+"]", stockIssuance.line_items[i].cancel_quantity.length>0 ? stockIssuance.line_items[i].cancel_quantity : 0);
        formData.append("quantity["+i+"]", stockIssuance.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", stockIssuance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockIssuance.line_items[i].uom!==null ? stockIssuance.line_items[i].uom.value : "");
        formData.append("from_location["+i+"]", stockIssuance.line_items[i].from_location!==null ? stockIssuance.line_items[i].from_location.value : "");
        for (let j=0; j<stockIssuance.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", stockIssuance.line_items[i].serial_numbers[j] ? stockIssuance.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockIssuance.line_items[i].item_conditions[j] ? stockIssuance.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<stockIssuance.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", stockIssuance.line_items[i].expiry_dates[j] ? stockIssuance.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", stockIssuance.line_items[i].expiry_quantities[j] ? stockIssuance.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockIssuance.line_items[i].item_conditions[j] ? stockIssuance.line_items[i].item_conditions[j] : "");
        }
    }
    if (stockIssuance.attachments) {
        for (let i=0; i<stockIssuance.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockIssuance.attachments[i]!==null ? stockIssuance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveStockIssuance(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectStockIssuance(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidStockIssuance(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createFinishedGoodProduced(finishedGoodProduced = {}) {
    const formData = new FormData();
    formData.append("document_date", finishedGoodProduced.document_date);
    formData.append("reference_number", finishedGoodProduced.reference_number);
    formData.append("number_of_batches", finishedGoodProduced.number_of_batches);
    formData.append("finished_goods_produced", finishedGoodProduced.finished_goods_produced ? finishedGoodProduced.finished_goods_produced.value : "");
    formData.append("standard_production", finishedGoodProduced.standard_production);
    formData.append("actual_production", finishedGoodProduced.actual_production);
    formData.append("direct_labor_cost", finishedGoodProduced.direct_labor_cost);
    formData.append("overhead_cost", finishedGoodProduced.overhead_cost);
    formData.append("total_production_cost", finishedGoodProduced.total_production_cost);
    formData.append("finished_good_unit_price", finishedGoodProduced.finished_good_unit_price);
    formData.append("transaction_description", finishedGoodProduced.transaction_description);
    for (let i=0; i<finishedGoodProduced.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", finishedGoodProduced.selected_document_ids[i]);
    }
    for (let i=0; i<finishedGoodProduced.line_items.length; i++) {
        formData.append("item["+i+"]", finishedGoodProduced.line_items[i].item!==null ? finishedGoodProduced.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", finishedGoodProduced.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", finishedGoodProduced.line_items[i].unit_cost);
        formData.append("uom["+i+"]", finishedGoodProduced.line_items[i].uom!==null ? finishedGoodProduced.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", finishedGoodProduced.line_items[i].location!==null ? finishedGoodProduced.line_items[i].location.value : "");
        for (let j=0; j<finishedGoodProduced.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", finishedGoodProduced.line_items[i].serial_numbers[j]);
        }
        for (let j=0; j<finishedGoodProduced.line_items[i].expiry_quantities.length; j++) {
            formData.append("expiry_quantities["+i+"]["+j+"]", finishedGoodProduced.line_items[i].expiry_quantities[j]);
        }
        for (let j=0; j<finishedGoodProduced.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", finishedGoodProduced.line_items[i].expiry_dates[j]);
        }
    }
    if (finishedGoodProduced.attachments) {
        for (let i=0; i<finishedGoodProduced.attachments.length; i++) {
            formData.append("attachments["+i+"]", finishedGoodProduced.attachments[i]!==null ? finishedGoodProduced.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/finished-good-produce", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateFinishedGoodProduced(finishedGoodProduced = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", finishedGoodProduced.document_date);
    formData.append("reference_number", finishedGoodProduced.reference_number);
    formData.append("number_of_batches", finishedGoodProduced.number_of_batches);
    formData.append("finished_goods_produced", finishedGoodProduced.finished_goods_produced ? finishedGoodProduced.finished_goods_produced.value : "");
    formData.append("standard_production", finishedGoodProduced.standard_production);
    formData.append("actual_production", finishedGoodProduced.actual_production);
    formData.append("direct_labor_cost", finishedGoodProduced.direct_labor_cost);
    formData.append("overhead_cost", finishedGoodProduced.overhead_cost);
    formData.append("total_production_cost", finishedGoodProduced.total_production_cost);
    formData.append("finished_good_unit_price", finishedGoodProduced.finished_good_unit_price);
    formData.append("transaction_description", finishedGoodProduced.transaction_description);
    for (let i=0; i<finishedGoodProduced.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", finishedGoodProduced.selected_document_ids[i]);
    }
    for (let i=0; i<finishedGoodProduced.line_items.length; i++) {
        formData.append("item["+i+"]", finishedGoodProduced.line_items[i].item!==null ? finishedGoodProduced.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", finishedGoodProduced.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", finishedGoodProduced.line_items[i].unit_cost);
        formData.append("uom["+i+"]", finishedGoodProduced.line_items[i].uom!==null ? finishedGoodProduced.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", finishedGoodProduced.line_items[i].location!==null ? finishedGoodProduced.line_items[i].location.value : "");
        for (let j=0; j<finishedGoodProduced.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", finishedGoodProduced.line_items[i].serial_numbers[j]);
        }
        for (let j=0; j<finishedGoodProduced.line_items[i].expiry_quantities.length; j++) {
            formData.append("expiry_quantities["+i+"]["+j+"]", finishedGoodProduced.line_items[i].expiry_quantities[j]);
        }
        for (let j=0; j<finishedGoodProduced.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", finishedGoodProduced.line_items[i].expiry_dates[j]);
        }
    }
    if (finishedGoodProduced.attachments) {
        for (let i=0; i<finishedGoodProduced.attachments.length; i++) {
            formData.append("attachments["+i+"]", finishedGoodProduced.attachments[i]!==null ? finishedGoodProduced.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/finished-good-produces/${finishedGoodProduced.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitFinishedGoodProduced(finishedGoodProduced = {}) {
    let formData = new FormData();
    formData.append("id", finishedGoodProduced.id);
    formData.append("document_date", finishedGoodProduced.document_date);
    formData.append("reference_number", finishedGoodProduced.reference_number);
    formData.append("number_of_batches", finishedGoodProduced.number_of_batches);
    formData.append("finished_goods_produced", finishedGoodProduced.finished_goods_produced ? finishedGoodProduced.finished_goods_produced.value : "");
    formData.append("standard_production", finishedGoodProduced.standard_production);
    formData.append("actual_production", finishedGoodProduced.actual_production);
    formData.append("direct_labor_cost", finishedGoodProduced.direct_labor_cost);
    formData.append("overhead_cost", finishedGoodProduced.overhead_cost);
    formData.append("total_production_cost", finishedGoodProduced.total_production_cost);
    formData.append("finished_good_unit_price", finishedGoodProduced.finished_good_unit_price);
    formData.append("transaction_description", finishedGoodProduced.transaction_description);
    for (let i=0; i<finishedGoodProduced.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", finishedGoodProduced.selected_document_ids[i]);
    }
    for (let i=0; i<finishedGoodProduced.line_items.length; i++) {
        formData.append("item["+i+"]", finishedGoodProduced.line_items[i].item!==null ? finishedGoodProduced.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", finishedGoodProduced.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", finishedGoodProduced.line_items[i].unit_cost);
        formData.append("uom["+i+"]", finishedGoodProduced.line_items[i].uom!==null ? finishedGoodProduced.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", finishedGoodProduced.line_items[i].location!==null ? finishedGoodProduced.line_items[i].location.value : "");
        for (let j=0; j<finishedGoodProduced.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", finishedGoodProduced.line_items[i].serial_numbers[j] ? finishedGoodProduced.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", finishedGoodProduced.line_items[i].item_conditions[j] ? finishedGoodProduced.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<finishedGoodProduced.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", finishedGoodProduced.line_items[i].expiry_dates[j] ? finishedGoodProduced.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", finishedGoodProduced.line_items[i].expiry_quantities[j] ? finishedGoodProduced.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", finishedGoodProduced.line_items[i].item_conditions[j] ? finishedGoodProduced.line_items[i].item_conditions[j] : "");
        }
    }
    if (finishedGoodProduced.attachments) {
        for (let i=0; i<finishedGoodProduced.attachments.length; i++) {
            formData.append("attachments["+i+"]", finishedGoodProduced.attachments[i]!==null ? finishedGoodProduced.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/finished-good-produces/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveFinishedGoodProduced(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectFinishedGoodProduced(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidFinishedGoodProduced(stockIssuance = {}) {
    let formData = new FormData();
    formData.append("id", stockIssuance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/${stockIssuance.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateInventoryTrackings(inventoryTrackings = {}) {
    let formData = new FormData();
    for (let i=0; i<inventoryTrackings.length; i++) {
        //formData.append("document_date[]", stockAdjustments[i][0]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/inventory-tracking/update`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function firstInFirstOut(query = {}) {
    const params = new URLSearchParams();
    params.append('item_id', query.item_id);
    params.append('quantity', query.quantity);
    params.append('unit_of_measure_id', query.unit_of_measure_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/inventory-tracking/first-in-first-out`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function lastInFirstOut(query = {}) {
    const params = new URLSearchParams();
    params.append('item_id', query.item_id);
    params.append('quantity', query.quantity);
    params.append('unit_of_measure_id', query.unit_of_measure_id);
    return new Promise((resolve, reject) =>
        api
            .get(`/api/inventory-tracking/last-in-first-out`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function firstOutFirstIn(query = {}) {
    const params = new URLSearchParams();
    params.append('item_id', query.item_id);
    for (let i=0; i<query.selected_document_ids.length; i++) {
        params.append("selected_document_ids[]", query.selected_document_ids[i]);
    }
    return new Promise((resolve, reject) =>
        api
            .get(`/api/inventory-tracking/first-out-first-in`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function createStockAcceptance(stockAcceptance = {}) {
    const formData = new FormData();
    formData.append("document_date", stockAcceptance.document_date);
    formData.append("reference_number", stockAcceptance.reference_number);
    formData.append("transaction_source", stockAcceptance.transaction_source ? stockAcceptance.transaction_source.value : "");
    formData.append("transaction_description", stockAcceptance.transaction_description);
    formData.append("transfer_from", stockAcceptance.transfer_from ? JSON.stringify(stockAcceptance.transfer_from) : '');
    formData.append("transfer_to", stockAcceptance.transfer_to ? JSON.stringify(stockAcceptance.transfer_to) : '');
    for (let i=0; i<stockAcceptance.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", stockAcceptance.selected_document_ids[i]);
    }
    for (let i=0; i<stockAcceptance.line_items.length; i++) {
        formData.append("item["+i+"]", stockAcceptance.line_items[i].item!==null ? stockAcceptance.line_items[i].item.value : "");
        formData.append("requested_quantity["+i+"]", stockAcceptance.line_items[i].requested_quantity);
        formData.append("issued_quantity["+i+"]", stockAcceptance.line_items[i].issued_quantity);
        formData.append("cancelled_quantity["+i+"]", stockAcceptance.line_items[i].cancelled_quantity);
        formData.append("quantity["+i+"]", stockAcceptance.line_items[i].quantity);
        formData.append("cancel_quantity["+i+"]", stockAcceptance.line_items[i].cancel_quantity);
        formData.append("unit_cost["+i+"]", stockAcceptance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockAcceptance.line_items[i].uom!==null ? stockAcceptance.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", stockAcceptance.line_items[i].to_location!==null ? stockAcceptance.line_items[i].to_location.value : "");
    }
    if (stockAcceptance.attachments) {
        for (let i=0; i<stockAcceptance.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockAcceptance.attachments[i]!==null ? stockAcceptance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/stock-acceptance", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateStockAcceptance(stockAcceptance = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", stockAcceptance.document_date);
    formData.append("reference_number", stockAcceptance.reference_number);
    formData.append("transaction_source", stockAcceptance.transaction_source ? stockAcceptance.transaction_source.value : "");
    formData.append("transaction_description", stockAcceptance.transaction_description);
    formData.append("transfer_from", stockAcceptance.transfer_from ? JSON.stringify(stockAcceptance.transfer_from) : '');
    formData.append("transfer_to", stockAcceptance.transfer_to ? JSON.stringify(stockAcceptance.transfer_to) : '');
    for (let i=0; i<stockAcceptance.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", stockAcceptance.selected_document_ids[i]);
    }
    for (let i=0; i<stockAcceptance.line_items.length; i++) {
        formData.append("item["+i+"]", stockAcceptance.line_items[i].item!==null ? stockAcceptance.line_items[i].item.value : "");
        formData.append("requested_quantity["+i+"]", stockAcceptance.line_items[i].requested_quantity);
        formData.append("issued_quantity["+i+"]", stockAcceptance.line_items[i].issued_quantity);
        formData.append("cancelled_quantity["+i+"]", stockAcceptance.line_items[i].cancelled_quantity);
        formData.append("quantity["+i+"]", stockAcceptance.line_items[i].quantity);
        formData.append("cancel_quantity["+i+"]", stockAcceptance.line_items[i].cancel_quantity);
        formData.append("unit_cost["+i+"]", stockAcceptance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockAcceptance.line_items[i].uom!==null ? stockAcceptance.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", stockAcceptance.line_items[i].to_location!==null ? stockAcceptance.line_items[i].to_location.value : "");
        for (let j=0; j<stockAcceptance.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", stockAcceptance.line_items[i].serial_numbers[j] ? stockAcceptance.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAcceptance.line_items[i].item_conditions[j] ? stockAcceptance.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<stockAcceptance.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", stockAcceptance.line_items[i].expiry_dates[j] ? stockAcceptance.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", stockAcceptance.line_items[i].expiry_quantities[j] ? stockAcceptance.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAcceptance.line_items[i].item_conditions[j] ? stockAcceptance.line_items[i].item_conditions[j] : "");
        }
    }
    if (stockAcceptance.attachments) {
        for (let i=0; i<stockAcceptance.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockAcceptance.attachments[i]!==null ? stockAcceptance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-acceptances/${stockAcceptance.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitStockAcceptance(stockAcceptance = {}) {
    let formData = new FormData();
    formData.append("id", stockAcceptance.id);
    formData.append("document_date", stockAcceptance.document_date);
    formData.append("reference_number", stockAcceptance.reference_number);
    formData.append("transaction_source", stockAcceptance.transaction_source ? stockAcceptance.transaction_source.value : "");
    formData.append("transaction_description", stockAcceptance.transaction_description);
    formData.append("transfer_from", stockAcceptance.transfer_from ? JSON.stringify(stockAcceptance.transfer_from) : '');
    formData.append("transfer_to", stockAcceptance.transfer_to ? JSON.stringify(stockAcceptance.transfer_to) : '');
    for (let i=0; i<stockAcceptance.selected_document_ids.length; i++) {
        formData.append("selected_document_ids[]", stockAcceptance.selected_document_ids[i]);
    }
    for (let i=0; i<stockAcceptance.line_items.length; i++) {
        formData.append("item["+i+"]", stockAcceptance.line_items[i].item!==null ? stockAcceptance.line_items[i].item.value : "");
        formData.append("requested_quantity["+i+"]", stockAcceptance.line_items[i].requested_quantity);
        formData.append("issued_quantity["+i+"]", stockAcceptance.line_items[i].issued_quantity);
        formData.append("cancelled_quantity["+i+"]", stockAcceptance.line_items[i].cancelled_quantity);
        formData.append("quantity["+i+"]", stockAcceptance.line_items[i].quantity);
        formData.append("cancel_quantity["+i+"]", stockAcceptance.line_items[i].cancel_quantity);
        formData.append("unit_cost["+i+"]", stockAcceptance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockAcceptance.line_items[i].uom!==null ? stockAcceptance.line_items[i].uom.value : "");
        formData.append("to_location["+i+"]", stockAcceptance.line_items[i].to_location!==null ? stockAcceptance.line_items[i].to_location.value : "");
        for (let j=0; j<stockAcceptance.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", stockAcceptance.line_items[i].serial_numbers[j] ? stockAcceptance.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAcceptance.line_items[i].item_conditions[j] ? stockAcceptance.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<stockAcceptance.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", stockAcceptance.line_items[i].expiry_dates[j] ? stockAcceptance.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", stockAcceptance.line_items[i].expiry_quantities[j] ? stockAcceptance.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAcceptance.line_items[i].item_conditions[j] ? stockAcceptance.line_items[i].item_conditions[j] : "");
        }
    }
    if (stockAcceptance.attachments) {
        for (let i=0; i<stockAcceptance.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockAcceptance.attachments[i]!==null ? stockAcceptance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-acceptances/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveStockAcceptance(stockAcceptance = {}) {
    let formData = new FormData();
    formData.append("id", stockAcceptance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-acceptances/${stockAcceptance.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectStockAcceptance(stockAcceptance = {}) {
    let formData = new FormData();
    formData.append("id", stockAcceptance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-acceptances/${stockAcceptance.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidStockAcceptance(stockAcceptance = {}) {
    let formData = new FormData();
    formData.append("id", stockAcceptance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-acceptances/${stockAcceptance.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importStockAcceptances(stockAcceptances = {}) {
    let formData = new FormData();
    for (let i=0; i<stockAcceptances.length; i++) {
        formData.append("document_date[]", stockAcceptances[i][0]);
        formData.append("reference_number[]", stockAcceptances[i][1]);
        formData.append("transaction_description[]", stockAcceptances[i][2]);
        formData.append("requesting_unit_code[]", stockAcceptances[i][3]);
        formData.append("profit_cost_center_type[]", stockAcceptances[i][4]);
        formData.append("item_code[]", stockAcceptances[i][5]);
        formData.append("quantity[]", stockAcceptances[i][6]);
        formData.append("unit_cost[]", stockAcceptances[i][7]);
        formData.append("uom[]", stockAcceptances[i][8]);
        formData.append("to_location_code[]", stockAcceptances[i][9]);
        formData.append("serial_number[]", stockAcceptances[i][10]);
        formData.append("expiry_date[]", stockAcceptances[i][11]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-acceptances/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createStockAdjustment(stockAdjustment = {}) {
    const formData = new FormData();
    formData.append("document_date", stockAdjustment.document_date);
    formData.append("reference_number", stockAdjustment.reference_number);
    formData.append("profit_cost_center", stockAdjustment.profit_cost_center ? JSON.stringify(stockAdjustment.profit_cost_center) : '');
    formData.append("transaction_description", stockAdjustment.transaction_description);
    for (let i=0; i<stockAdjustment.line_items.length; i++) {
        formData.append("item["+i+"]", stockAdjustment.line_items[i].item!==null ? stockAdjustment.line_items[i].item.value : "");
        formData.append("operation["+i+"]", stockAdjustment.line_items[i].increment ? stockAdjustment.operations.INCREASE : stockAdjustment.operations.DECREASE);
        formData.append("quantity["+i+"]", stockAdjustment.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", stockAdjustment.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockAdjustment.line_items[i].uom!==null ? stockAdjustment.line_items[i].uom.value : "");
        formData.append("location["+i+"]", stockAdjustment.line_items[i].location!==null ? stockAdjustment.line_items[i].location.value : "");
    }
    if (stockAdjustment.attachments) {
        for (let i=0; i<stockAdjustment.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockAdjustment.attachments[i]!==null ? stockAdjustment.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/stock-adjustment", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateStockAdjustment(stockAdjustment = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", stockAdjustment.document_date);
    formData.append("reference_number", stockAdjustment.reference_number);
    formData.append("transaction_description", stockAdjustment.transaction_description);
    formData.append("profit_cost_center", stockAdjustment.profit_cost_center ? JSON.stringify(stockAdjustment.profit_cost_center) : '');
    for (let i=0; i<stockAdjustment.line_items.length; i++) {
        formData.append("item["+i+"]", stockAdjustment.line_items[i].item!==null ? stockAdjustment.line_items[i].item.value : "");
        formData.append("operation["+i+"]", stockAdjustment.line_items[i].increment ? stockAdjustment.operations.INCREASE : stockAdjustment.operations.DECREASE);
        formData.append("quantity["+i+"]", stockAdjustment.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", stockAdjustment.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockAdjustment.line_items[i].uom!==null ? stockAdjustment.line_items[i].uom.value : "");
        formData.append("location["+i+"]", stockAdjustment.line_items[i].location!==null ? stockAdjustment.line_items[i].location.value : "");
        for (let j=0; j<stockAdjustment.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", stockAdjustment.line_items[i].serial_numbers[j] ? stockAdjustment.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAdjustment.line_items[i].item_conditions[j] ? stockAdjustment.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<stockAdjustment.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", stockAdjustment.line_items[i].expiry_dates[j] ? stockAdjustment.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", stockAdjustment.line_items[i].expiry_quantities[j] ? stockAdjustment.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAdjustment.line_items[i].item_conditions[j] ? stockAdjustment.line_items[i].item_conditions[j] : "");
        }
    }
    if (stockAdjustment.attachments) {
        for (let i=0; i<stockAdjustment.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockAdjustment.attachments[i]!==null ? stockAdjustment.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-adjustments/${stockAdjustment.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitStockAdjustment(stockAdjustment = {}) {
    let formData = new FormData();
    formData.append("id", stockAdjustment.id);
    formData.append("document_date", stockAdjustment.document_date);
    formData.append("reference_number", stockAdjustment.reference_number);
    formData.append("transaction_description", stockAdjustment.transaction_description);
    formData.append("profit_cost_center", stockAdjustment.profit_cost_center ? JSON.stringify(stockAdjustment.profit_cost_center) : '');
    for (let i=0; i<stockAdjustment.line_items.length; i++) {
        formData.append("item["+i+"]", stockAdjustment.line_items[i].item!==null ? stockAdjustment.line_items[i].item.value : "");
        formData.append("operation["+i+"]", stockAdjustment.line_items[i].increment ? stockAdjustment.operations.INCREASE : stockAdjustment.operations.DECREASE);
        formData.append("quantity["+i+"]", stockAdjustment.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", stockAdjustment.line_items[i].unit_cost);
        formData.append("uom["+i+"]", stockAdjustment.line_items[i].uom!==null ? stockAdjustment.line_items[i].uom.value : "");
        formData.append("location["+i+"]", stockAdjustment.line_items[i].location!==null ? stockAdjustment.line_items[i].location.value : "");
        for (let j=0; j<stockAdjustment.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", stockAdjustment.line_items[i].serial_numbers[j] ? stockAdjustment.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAdjustment.line_items[i].item_conditions[j] ? stockAdjustment.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<stockAdjustment.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", stockAdjustment.line_items[i].expiry_dates[j] ? stockAdjustment.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", stockAdjustment.line_items[i].expiry_quantities[j] ? stockAdjustment.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", stockAdjustment.line_items[i].item_conditions[j] ? stockAdjustment.line_items[i].item_conditions[j] : "");
        }
    }
    if (stockAdjustment.attachments) {
        for (let i=0; i<stockAdjustment.attachments.length; i++) {
            formData.append("attachments["+i+"]", stockAdjustment.attachments[i]!==null ? stockAdjustment.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-adjustments/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveStockAdjustment(stockAdjustment = {}) {
    let formData = new FormData();
    formData.append("id", stockAdjustment.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-adjustments/${stockAdjustment.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectStockAdjustment(stockAdjustment = {}) {
    let formData = new FormData();
    formData.append("id", stockAdjustment.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-adjustments/${stockAdjustment.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidStockAdjustment(stockAdjustment = {}) {
    let formData = new FormData();
    formData.append("id", stockAdjustment.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-adjustments/${stockAdjustment.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importStockAdjustments(stockAdjustments = {}) {
    let formData = new FormData();
    for (let i=0; i<stockAdjustments.length; i++) {
        formData.append("document_date[]", stockAdjustments[i][0]);
        formData.append("reference_number[]", stockAdjustments[i][1]);
        formData.append("transaction_description[]", stockAdjustments[i][2]);
        formData.append("profit_cost_center_code[]", stockAdjustments[i][3]);
        formData.append("profit_cost_center_type[]", stockAdjustments[i][4]);
        formData.append("item_code[]", stockAdjustments[i][5]);
        formData.append("sign[]", stockAdjustments[i][6]);
        formData.append("quantity[]", stockAdjustments[i][7]);
        formData.append("unit_cost[]", stockAdjustments[i][8]);
        formData.append("uom[]", stockAdjustments[i][9]);
        formData.append("location_code[]", stockAdjustments[i][10]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-adjustments/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createItemLocationTransfer(itemLocationTransfer = {}) {
    const formData = new FormData();
    formData.append("document_date", itemLocationTransfer.document_date);
    formData.append("reference_number", itemLocationTransfer.reference_number);
    formData.append("transaction_description", itemLocationTransfer.transaction_description);
    formData.append("profit_cost_center", itemLocationTransfer.profit_cost_center ? JSON.stringify(itemLocationTransfer.profit_cost_center) : '');
    for (let i=0; i<itemLocationTransfer.line_items.length; i++) {
        formData.append("item["+i+"]", itemLocationTransfer.line_items[i].item!==null ? itemLocationTransfer.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", itemLocationTransfer.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", itemLocationTransfer.line_items[i].unit_cost);
        formData.append("uom["+i+"]", itemLocationTransfer.line_items[i].uom!==null ? itemLocationTransfer.line_items[i].uom.value : "");
        formData.append("from_location["+i+"]", itemLocationTransfer.line_items[i].from_location!==null ? itemLocationTransfer.line_items[i].from_location.value : "");
        formData.append("to_location["+i+"]", itemLocationTransfer.line_items[i].to_location!==null ? itemLocationTransfer.line_items[i].to_location.value : "");
    }
    if (itemLocationTransfer.attachments) {
        for (let i=0; i<itemLocationTransfer.attachments.length; i++) {
            formData.append("attachments["+i+"]", itemLocationTransfer.attachments[i]!==null ? itemLocationTransfer.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/item-location-transfer", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateItemLocationTransfer(itemLocationTransfer = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", itemLocationTransfer.document_date);
    formData.append("reference_number", itemLocationTransfer.reference_number);
    formData.append("transaction_description", itemLocationTransfer.transaction_description);
    formData.append("profit_cost_center", itemLocationTransfer.profit_cost_center ? JSON.stringify(itemLocationTransfer.profit_cost_center) : '');
    for (let i=0; i<itemLocationTransfer.line_items.length; i++) {
        formData.append("item["+i+"]", itemLocationTransfer.line_items[i].item!==null ? itemLocationTransfer.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", itemLocationTransfer.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", itemLocationTransfer.line_items[i].unit_cost);
        formData.append("uom["+i+"]", itemLocationTransfer.line_items[i].uom!==null ? itemLocationTransfer.line_items[i].uom.value : "");
        formData.append("from_location["+i+"]", itemLocationTransfer.line_items[i].from_location!==null ? itemLocationTransfer.line_items[i].from_location.value : "");
        formData.append("to_location["+i+"]", itemLocationTransfer.line_items[i].to_location!==null ? itemLocationTransfer.line_items[i].to_location.value : "");
        for (let j=0; j<itemLocationTransfer.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", itemLocationTransfer.line_items[i].serial_numbers[j] ? itemLocationTransfer.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", itemLocationTransfer.line_items[i].item_conditions[j] ? itemLocationTransfer.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<itemLocationTransfer.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", itemLocationTransfer.line_items[i].expiry_dates[j] ? itemLocationTransfer.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", itemLocationTransfer.line_items[i].expiry_quantities[j] ? itemLocationTransfer.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", itemLocationTransfer.line_items[i].item_conditions[j] ? itemLocationTransfer.line_items[i].item_conditions[j] : "");
        }
    }
    if (itemLocationTransfer.attachments) {
        for (let i=0; i<itemLocationTransfer.attachments.length; i++) {
            formData.append("attachments["+i+"]", itemLocationTransfer.attachments[i]!==null ? itemLocationTransfer.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-location-transfers/${itemLocationTransfer.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitItemLocationTransfer(itemLocationTransfer = {}) {
    let formData = new FormData();
    formData.append("id", itemLocationTransfer.id);
    formData.append("document_date", itemLocationTransfer.document_date);
    formData.append("reference_number", itemLocationTransfer.reference_number);
    formData.append("transaction_description", itemLocationTransfer.transaction_description);
    formData.append("profit_cost_center", itemLocationTransfer.profit_cost_center ? JSON.stringify(itemLocationTransfer.profit_cost_center) : '');
    for (let i=0; i<itemLocationTransfer.line_items.length; i++) {
        formData.append("item["+i+"]", itemLocationTransfer.line_items[i].item!==null ? itemLocationTransfer.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", itemLocationTransfer.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", itemLocationTransfer.line_items[i].unit_cost);
        formData.append("uom["+i+"]", itemLocationTransfer.line_items[i].uom!==null ? itemLocationTransfer.line_items[i].uom.value : "");
        formData.append("from_location["+i+"]", itemLocationTransfer.line_items[i].from_location!==null ? itemLocationTransfer.line_items[i].from_location.value : "");
        formData.append("to_location["+i+"]", itemLocationTransfer.line_items[i].to_location!==null ? itemLocationTransfer.line_items[i].to_location.value : "");
        for (let j=0; j<itemLocationTransfer.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", itemLocationTransfer.line_items[i].serial_numbers[j] ? itemLocationTransfer.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", itemLocationTransfer.line_items[i].item_conditions[j] ? itemLocationTransfer.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<itemLocationTransfer.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", itemLocationTransfer.line_items[i].expiry_dates[j] ? itemLocationTransfer.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", itemLocationTransfer.line_items[i].expiry_quantities[j] ? itemLocationTransfer.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", itemLocationTransfer.line_items[i].item_conditions[j] ? itemLocationTransfer.line_items[i].item_conditions[j] : "");
        }
    }
    if (itemLocationTransfer.attachments) {
        for (let i=0; i<itemLocationTransfer.attachments.length; i++) {
            formData.append("attachments["+i+"]", itemLocationTransfer.attachments[i]!==null ? itemLocationTransfer.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-location-transfers/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveItemLocationTransfer(itemLocationTransfer = {}) {
    let formData = new FormData();
    formData.append("id", itemLocationTransfer.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-location-transfers/${itemLocationTransfer.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectItemLocationTransfer(itemLocationTransfer = {}) {
    let formData = new FormData();
    formData.append("id", itemLocationTransfer.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-location-transfers/${itemLocationTransfer.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidItemLocationTransfer(itemLocationTransfer = {}) {
    let formData = new FormData();
    formData.append("id", itemLocationTransfer.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-location-transfers/${itemLocationTransfer.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importItemLocationTransfers(itemLocationTransfers = {}) {
    let formData = new FormData();
    for (let i=0; i<itemLocationTransfers.length; i++) {
        formData.append("document_date[]", itemLocationTransfers[i][0]);
        formData.append("reference_number[]", itemLocationTransfers[i][1]);
        formData.append("transaction_description[]", itemLocationTransfers[i][2]);
        formData.append("item_code[]", itemLocationTransfers[i][3]);
        formData.append("quantity[]", itemLocationTransfers[i][4]);
        formData.append("uom[]", itemLocationTransfers[i][5]);
        formData.append("from_location_code[]", itemLocationTransfers[i][6]);
        formData.append("to_location_code[]", itemLocationTransfers[i][7]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/item-location-transfers/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function createBeginningBalance(beginningBalance = {}) {
    const formData = new FormData();
    formData.append("document_date", beginningBalance.document_date);
    formData.append("reference_number", beginningBalance.reference_number);
    formData.append("profit_cost_center", beginningBalance.profit_cost_center ? JSON.stringify(beginningBalance.profit_cost_center) : '');
    formData.append("transaction_description", beginningBalance.transaction_description);
    for (let i=0; i<beginningBalance.line_items.length; i++) {
        formData.append("item["+i+"]", beginningBalance.line_items[i].item!==null ? beginningBalance.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", beginningBalance.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", beginningBalance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", beginningBalance.line_items[i].uom!==null ? beginningBalance.line_items[i].uom.value : "");
        formData.append("location["+i+"]", beginningBalance.line_items[i].location!==null ? beginningBalance.line_items[i].location.value : "");
    }
    if (beginningBalance.attachments) {
        for (let i=0; i<beginningBalance.attachments.length; i++) {
            formData.append("attachments["+i+"]", beginningBalance.attachments[i]!==null ? beginningBalance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/beginning-balance", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateBeginningBalance(beginningBalance = {}) {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("document_date", beginningBalance.document_date);
    formData.append("reference_number", beginningBalance.reference_number);
    formData.append("profit_cost_center", beginningBalance.profit_cost_center ? JSON.stringify(beginningBalance.profit_cost_center) : '');
    formData.append("transaction_description", beginningBalance.transaction_description);
    for (let i=0; i<beginningBalance.line_items.length; i++) {
        formData.append("item["+i+"]", beginningBalance.line_items[i].item!==null ? beginningBalance.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", beginningBalance.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", beginningBalance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", beginningBalance.line_items[i].uom!==null ? beginningBalance.line_items[i].uom.value : "");
        formData.append("location["+i+"]", beginningBalance.line_items[i].location!==null ? beginningBalance.line_items[i].location.value : "");
        for (let j=0; j<beginningBalance.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", beginningBalance.line_items[i].serial_numbers[j] ? beginningBalance.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", beginningBalance.line_items[i].item_conditions[j] ? beginningBalance.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<beginningBalance.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", beginningBalance.line_items[i].expiry_dates[j] ? beginningBalance.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", beginningBalance.line_items[i].expiry_quantities[j] ? beginningBalance.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", beginningBalance.line_items[i].item_conditions[j] ? beginningBalance.line_items[i].item_conditions[j] : "");
        }
    }
    if (beginningBalance.attachments) {
        for (let i=0; i<beginningBalance.attachments.length; i++) {
            formData.append("attachments["+i+"]", beginningBalance.attachments[i]!==null ? beginningBalance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/beginning-balances/${beginningBalance.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function submitBeginningBalance(beginningBalance = {}) {
    let formData = new FormData();
    formData.append("id", beginningBalance.id);
    formData.append("document_date", beginningBalance.document_date);
    formData.append("reference_number", beginningBalance.reference_number);
    formData.append("profit_cost_center", beginningBalance.profit_cost_center ? JSON.stringify(beginningBalance.profit_cost_center) : '');
    formData.append("transaction_description", beginningBalance.transaction_description);
    for (let i=0; i<beginningBalance.line_items.length; i++) {
        formData.append("item["+i+"]", beginningBalance.line_items[i].item!==null ? beginningBalance.line_items[i].item.value : "");
        formData.append("quantity["+i+"]", beginningBalance.line_items[i].quantity);
        formData.append("unit_cost["+i+"]", beginningBalance.line_items[i].unit_cost);
        formData.append("uom["+i+"]", beginningBalance.line_items[i].uom!==null ? beginningBalance.line_items[i].uom.value : "");
        formData.append("location["+i+"]", beginningBalance.line_items[i].location!==null ? beginningBalance.line_items[i].location.value : "");
        for (let j=0; j<beginningBalance.line_items[i].serial_numbers.length; j++) {
            formData.append("serial_numbers["+i+"]["+j+"]", beginningBalance.line_items[i].serial_numbers[j] ? beginningBalance.line_items[i].serial_numbers[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", beginningBalance.line_items[i].item_conditions[j] ? beginningBalance.line_items[i].item_conditions[j] : "");
        }
        for (let j=0; j<beginningBalance.line_items[i].expiry_dates.length; j++) {
            formData.append("expiry_dates["+i+"]["+j+"]", beginningBalance.line_items[i].expiry_dates[j] ? beginningBalance.line_items[i].expiry_dates[j] : "");
            formData.append("expiry_quantities["+i+"]["+j+"]", beginningBalance.line_items[i].expiry_quantities[j] ? beginningBalance.line_items[i].expiry_quantities[j] : "");
            formData.append("item_conditions["+i+"]["+j+"]", beginningBalance.line_items[i].item_conditions[j] ? beginningBalance.line_items[i].item_conditions[j] : "");
        }
    }
    if (beginningBalance.attachments) {
        for (let i=0; i<beginningBalance.attachments.length; i++) {
            formData.append("attachments["+i+"]", beginningBalance.attachments[i]!==null ? beginningBalance.attachments[i] : "");
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/beginning-balances/submit`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function approveBeginningBalance(beginningBalance = {}) {
    let formData = new FormData();
    formData.append("id", beginningBalance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/beginning-balances/${beginningBalance.id}/approve`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function rejectBeginningBalance(beginningBalance = {}) {
    let formData = new FormData();
    formData.append("id", beginningBalance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/beginning-balances/${beginningBalance.id}/reject`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function voidBeginningBalance(beginningBalance = {}) {
    let formData = new FormData();
    formData.append("id", beginningBalance.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/beginning-balances/${beginningBalance.id}/void`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importBeginningBalances(beginningBalances = {}) {
    let formData = new FormData();
    for (let i=0; i<beginningBalances.length; i++) {
        formData.append("reference_number[]", beginningBalances[i][0]);
        formData.append("transaction_description[]", beginningBalances[i][1]);
        formData.append("item_code[]", beginningBalances[i][2]);
        formData.append("quantity[]", beginningBalances[i][3]);
        formData.append("unit_cost[]", beginningBalances[i][4]);
        formData.append("uom[]", beginningBalances[i][5]);
        formData.append("location_code[]", beginningBalances[i][6]);
        formData.append("expiry_date[]", beginningBalances[i][7]);
        formData.append("serial_number[]", beginningBalances[i][8]);
        formData.append("item_condition[]", beginningBalances[i][9]);
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/beginning-balances/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importStockIssuances(data = {}) {
    let formData = new FormData();
    let transactionSource = data.transaction_source ? data.transaction_source.value : "";
    formData.append("transaction_source", transactionSource);
    switch (transactionSource) {
        case data.transaction_sources.DIRECT_ISSUANCE: {
            for (let i=0; i<data.stock_issuances.length; i++) {
                formData.append("document_date[]", data.stock_issuances[i][0]);
                formData.append("reference_number[]", data.stock_issuances[i][1]);
                formData.append("transaction_description[]", data.stock_issuances[i][2]);
                formData.append("transfer_to_profit_cost_center_code[]", data.stock_issuances[i][3]);
                formData.append("transfer_to_profit_cost_center_type[]", data.stock_issuances[i][4]);
                formData.append("item_code[]", data.stock_issuances[i][5]);
                formData.append("quantity[]", data.stock_issuances[i][6]);
                formData.append("uom[]", data.stock_issuances[i][7]);
                formData.append("from_location_code[]", data.stock_issuances[i][8]);
            }
            break;
        }
        case data.transaction_sources.INTERNAL_DELIVERY_RECEIPT: {
            for (let i=0; i<data.stock_issuances.length; i++) {
                formData.append("document_date[]", data.stock_issuances[i][0]);
                formData.append("reference_number[]", data.stock_issuances[i][1]);
                formData.append("transaction_description[]", data.stock_issuances[i][2]);
                formData.append("ship_to[]", data.stock_issuances[i][3]);
                formData.append("item_code[]", data.stock_issuances[i][4]);
                formData.append("quantity[]", data.stock_issuances[i][5]);
                formData.append("uom[]", data.stock_issuances[i][6]);
                formData.append("from_location_code[]", data.stock_issuances[i][7]);
            }
            break;
        }
        case data.transaction_sources.EXTERNAL_DELIVERY_RECEIPT: {
            for (let i=0; i<data.stock_issuances.length; i++) {
                formData.append("document_date[]", data.stock_issuances[i][0]);
                formData.append("reference_number[]", data.stock_issuances[i][1]);
                formData.append("transaction_description[]", data.stock_issuances[i][2]);
                formData.append("ship_to[]", data.stock_issuances[i][3]);
                formData.append("item_code[]", data.stock_issuances[i][4]);
                formData.append("quantity[]", data.stock_issuances[i][5]);
                formData.append("uom[]", data.stock_issuances[i][6]);
                formData.append("from_location_code[]", data.stock_issuances[i][7]);
            }
            break;
        }
        default: break;
    }
    
    return new Promise((resolve, reject) =>
        api
        .post(`/api/stock-issuances/import`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}


export function deleteItemClass(itemClass = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/item-classes/${itemClass.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteItemClasses(itemClasses = {}) {
    let formData = new FormData();
    for (let i=0; i<itemClasses.length; i++) {
        formData.append("ids[]", itemClasses[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/item-classes/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteUnitOfMeasureConversion(unitOfMeasureConversion = {}) {
    const formData = {};
    return new Promise((resolve, reject) =>
        api
        .delete(`/api/unit-of-measure-conversions/${unitOfMeasureConversion.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteUnitOfMeasureConversions(unitOfMeasureConversions = {}) {
    let formData = new FormData();
    for (let i=0; i<unitOfMeasureConversions.length; i++) {
        formData.append("ids[]", unitOfMeasureConversions[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/unit-of-measure-conversions/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importUnitOfMeasureConversions(unitOfMeasureConversions = {}) {
    const formData = new FormData();
    for (var i=0; i<unitOfMeasureConversions.length; i++) {
        if (unitOfMeasureConversions[i][0] || unitOfMeasureConversions[i][1] || unitOfMeasureConversions[i][2] || unitOfMeasureConversions[i][3] || unitOfMeasureConversions[i][4] || unitOfMeasureConversions[i][5]) {
            formData.append("uom_conversion_code[]", unitOfMeasureConversions[i][0]);
            formData.append("uom_conversion_description[]", unitOfMeasureConversions[i][1]);
            formData.append("source_quantity[]", unitOfMeasureConversions[i][2]);
            formData.append("source_unit_of_measure[]", unitOfMeasureConversions[i][3]);
            formData.append("convert_quantity[]", unitOfMeasureConversions[i][4]);
            formData.append("convert_unit_of_measure[]", unitOfMeasureConversions[i][5]);
        }
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/unit-of-measure-conversions", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function byPassStockCountSchedule() {
    const formData = new FormData();
    return new Promise((resolve, reject) =>
        api
        .post("/api/stock-counts/bypass", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function byPassYearEndClosing() {
    const formData = new FormData();
    return new Promise((resolve, reject) =>
        api
        .post("/api/year-end-closing", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateGeneralSettings(generalSettings = {}) {
    const formData = new FormData();
    formData.append("company_code", generalSettings.company_code);
    formData.append("company_name", generalSettings.company_name);
    formData.append("country", generalSettings.country);
    formData.append("currency", generalSettings.currency);
    formData.append("period_type", generalSettings.period_type ? generalSettings.period_type : '');
    formData.append("active_year", generalSettings.active_year ? generalSettings.active_year : '');
    formData.append("beginning_balance_date", generalSettings.beginning_balance_date ? generalSettings.beginning_balance_date : '');
    formData.append("stock_count_policy", generalSettings.stock_count_policy ? generalSettings.stock_count_policy : '');
    for (let i=0; i<generalSettings.stock_count_schedules.length; i++) {
        formData.append("stock_count_schedules[]", generalSettings.stock_count_schedules[i]);
    }
    formData.append("beginning_balance_serial_number_setup", generalSettings.beginning_balance_serial_number_setup ? generalSettings.beginning_balance_serial_number_setup : '');
    return new Promise((resolve, reject) =>
        api
        .post("/api/general-settings", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateLocation(location = {}) {
    return new Promise((resolve, reject) =>
        api
        .put(`/api/locations/${location.id}`, location)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateUnitOfMeasureConversion(unitOfMeasureConversion = {}) {
    return new Promise((resolve, reject) =>
        api
        .put(`/api/unit-of-measure-conversions/${unitOfMeasureConversion.id}`, unitOfMeasureConversion)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function saveCashierShift(cashierShift = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/cashier-shift", cashierShift)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function savePosTerminalSettings(posTerminalSettings = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/pos-terminal-settings", posTerminalSettings)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function createLaundryMachineSet(laundryMachineSet = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/laundry-machine-set", laundryMachineSet)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function createPosTerminal(posTerminal = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/pos-terminal", posTerminal)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function pullInventoryItems(inventoryItems = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/pos-items-and-services/pull-inventory-items", inventoryItems)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function removeInventoryItems(inventoryItems = {}) {
	return new Promise((resolve, reject) =>
		api
			.post("/api/pos-items-and-services/remove-inventory-items", inventoryItems)
			.then((response) =>
				resolve({
					data: response.data,
				})
			)
			.catch((error) =>
				reject({
					data: error.response ? error.response.data : null,
				})
			)
	);
}

export function createService(service = {}) {
    const formData = new FormData();
    formData.append("service_code", service.service_code);
    formData.append("service_name", service.service_name);
    formData.append("selling_price", service.selling_price);
    formData.append("tax", service.tax);
    formData.append("discount", service.discount);
    if (service.service_image!==null) {
        formData.append("service_image", service.service_image);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/pos-items-and-services/service", formData, {headers:{"Content-Type": "multipart/form-data"}})
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateService(service = {}) {
    const formData = {
        service_code: service.service_code,
        service_name: service.service_name,
        selling_price: service.selling_price,
        tax: service.tax,
        discount: service.discount,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/pos-items-and-services/services/${service.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function fetchAllTaxes(query = {}) {
    const params = new URLSearchParams();
    params.append("type", query.type ? query.type : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/taxes`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function createTax(params = {}) {
    const formData = {
        code: params.code,
        description: params.description,
        rate: params.rate,
        tax_type: params.tax_type,
        tax_computation: params.tax_computation,
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/tax`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateTax(params = {}) {
    const formData = {
        code: params.code,
        description: params.description,
        rate: params.rate,
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/taxes/${params.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteTaxes(taxes = {}) {
    let formData = new FormData();
    for (let i=0; i<taxes.length; i++) {
        formData.append("ids[]", taxes[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/taxes/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importTaxes(taxes = {}) {
    let formData = new FormData();
    for (let i=0; i<taxes.length; i++) {
        formData.append("type[]", taxes[i][0]);
        formData.append("code[]", taxes[i][1]);
        formData.append("description[]", taxes[i][2]);
        formData.append("rate[]", taxes[i][3]);
        formData.append("computation[]", taxes[i][4]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/taxes", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function fetchAllDiscounts(query = {}) {
    const params = new URLSearchParams();
    params.append("type", query.type ? query.type : "");
    params.append("source", query.source ? query.source : "");
    params.append("scheduled", query.scheduled ? query.scheduled : "");
    return new Promise((resolve, reject) =>
        api
            .get(`/api/discounts`, {
                params: params
            })
            .then((response) =>
                resolve({
                    data: response.data,
                })
            )
            .catch((error) =>
                reject({
                    data: error.response ? error.response.data : null,
                })
            )
    );
}

export function createDiscount(discount = {}) {
    const formData = {
        discount_type: discount.discount_type,
        source: discount.source,
        discount_rate: discount.discount_rate,
        description: discount.description,
        discount_start_date: discount.discount_start_date,
        discount_expiry_date: discount.discount_expiry_date,
        purchase_quantity: discount.purchase_quantity,
        freebie_quantity: discount.freebie_quantity,
        freebie_item: discount.freebie_item
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/discount`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateDiscount(discount = {}) {
    const formData = {
        discount_type: discount.discount_type,
        source: discount.source,
        discount_rate: discount.discount_rate,
        description: discount.description,
        discount_start_date: discount.discount_start_date,
        discount_expiry_date: discount.discount_expiry_date,
        purchase_quantity: discount.purchase_quantity,
        freebie_quantity: discount.freebie_quantity,
        freebie_item: discount.freebie_item
    };
    return new Promise((resolve, reject) =>
        api
        .put(`/api/discounts/${discount.id}`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function deleteDiscounts(discounts = {}) {
    let formData = new FormData();
    for (let i=0; i<discounts.length; i++) {
        formData.append("ids[]", discounts[i]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/discounts/batch-delete", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function importDiscounts(discounts = {}) {
    let formData = new FormData();
    for (let i=0; i<discounts.length; i++) {
        formData.append("source[]", discounts[i][0]);
        formData.append("type[]", discounts[i][1]);
        formData.append("description[]", discounts[i][2]);
        formData.append("rate[]", discounts[i][3]);
        formData.append("purchase_quantity[]", discounts[i][4]);
        formData.append("freebie_quantity[]", discounts[i][5]);
        formData.append("freebie_item_code[]", discounts[i][6]);
        formData.append("start_date[]", discounts[i][7]);
        formData.append("expiry_date[]", discounts[i][8]);
    }
    return new Promise((resolve, reject) =>
        api
        .post("/api/discounts", formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function reconCount(params = {}) {
    const formData = {
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/laundry-cycle-count/recon-count`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function forRecon(params = {}) {
    const formData = {
    };
    return new Promise((resolve, reject) =>
        api
        .post(`/api/laundry-cycle-count/for-recon`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function updateLaundryCycleCount(params = {}) {
    let formData = new FormData();
    for (let i=0; i<params.cycle_counts.length; i++) {
        let cc = params.cycle_counts[i];
        formData.append("id["+i+"]", cc.id);
        formData.append("dryer_current_cycle_count["+i+"]", cc.dryer_current_cycle_count);
        formData.append("washer_current_cycle_count["+i+"]", cc.washer_current_cycle_count);
        formData.append("dryer_cycle_count_for_the_day["+i+"]", cc.dryer_cycle_count_for_the_day);
        formData.append("washer_cycle_count_for_the_day["+i+"]", cc.washer_cycle_count_for_the_day);
        formData.append("dryer_difference["+i+"]", cc.dryer_difference);
        formData.append("washer_difference["+i+"]", cc.washer_difference);
        formData.append("dryer_reason_for_difference["+i+"]", cc.dryer_reason_for_difference ? cc.dryer_reason_for_difference : "");
        formData.append("washer_reason_for_difference["+i+"]", cc.washer_reason_for_difference ? cc.washer_reason_for_difference : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/laundry-cycle-count/update`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function finalizeLaundryCycleCount(params = {}) {
    let formData = new FormData();
    for (let i=0; i<params.cycle_counts.length; i++) {
        let cc = params.cycle_counts[i];
        formData.append("id["+i+"]", cc.id);
        formData.append("dryer_current_cycle_count["+i+"]", cc.dryer_current_cycle_count);
        formData.append("washer_current_cycle_count["+i+"]", cc.washer_current_cycle_count);
        formData.append("dryer_cycle_count_for_the_day["+i+"]", cc.dryer_cycle_count_for_the_day);
        formData.append("washer_cycle_count_for_the_day["+i+"]", cc.washer_cycle_count_for_the_day);
        formData.append("dryer_difference["+i+"]", cc.dryer_difference);
        formData.append("washer_difference["+i+"]", cc.washer_difference);
        formData.append("dryer_reason_for_difference["+i+"]", cc.dryer_reason_for_difference ? cc.dryer_reason_for_difference : "");
        formData.append("washer_reason_for_difference["+i+"]", cc.washer_reason_for_difference ? cc.washer_reason_for_difference : "");
    }
    return new Promise((resolve, reject) =>
        api
        .post(`/api/laundry-cycle-count/finalize`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}

export function resetPOSTerminal(params = {}) {
    let formData = new FormData();
    formData.append("id", params.id);
    return new Promise((resolve, reject) =>
        api
        .post(`/api/pos-terminal/reset`, formData)
        .then((response) =>
            resolve({
                data: response.data,
            })
        )
        .catch((error) =>
            reject({
                data: error.response ? error.response.data : null,
            })
        )
    );
}