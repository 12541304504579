import React, { useCallback, useEffect, useState } from "react";
import PDF from "../../../common/PDF";
import Excel from "../../../common/Excel";
import Util from "../../../common/Util";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAuth,
	updateNavigation,
	toggleSidebarCollapsed,
} from "../../auth/authSlice";
import { resources } from "../../../resources/index";
import {
	Button,
	ButtonGroup,
	Card,
	Icon
} from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import { SideBar } from '../../../SideBar';
import TextField from "@material-ui/core/TextField";
import { CashRemittanceSpreadsheet } from "../../../spreadsheets/CashRemittanceSpreadsheet";

export function CashRemittance() {
	const auth = useSelector(selectAuth);
	const dispatch = useDispatch();
	const translate = resources["lang"][auth.locale];
	const [from_date, setFromDate] = useState(null);
	const [to_date, setToDate] = useState(null);
	let DEFAULT_DATA_SET = new Array(1000).fill("").map(() => new Array(10).fill(""));
	const [worksheet, setWorkSheet] = useState({
		is_readonly_mode: true,
		num_rows: 1000,
		num_cols: 10,
		num_frozen_columns: 0,
		import_limit: 700,
		focused_cell: null,
		selected_regions: null,
		header: [],
		alignment: [],
		comment: [],
		data_set: DEFAULT_DATA_SET
	});

	const initFetch = useCallback(() => {
		dispatch(updateNavigation(translate.breadcrumb.CASH_REMITTANCE));
	}, [
		dispatch,
		translate.breadcrumb.CASH_REMITTANCE,
	]);

	useEffect(() => {
		initFetch();
	}, [initFetch]);

	const handleFreezePaneButtonClickedEvent = () => {
		if (worksheet.focused_cell) {
			setWorkSheet({
				is_readonly_mode: worksheet.is_readonly_mode,
				num_rows: worksheet.num_rows,
				num_cols: worksheet.num_cols,
				num_frozen_columns: worksheet.num_frozen_columns===0 ? worksheet.focused_cell.col : 0,
				import_limit: worksheet.import_limit,
				focused_cell: worksheet.focused_cell,
				selected_regions: worksheet.selected_regions,
				header: worksheet.header,
				alignment: worksheet.alignment,
				comment: worksheet.comment,
				data_set: worksheet.data_set
			});
		}
	}

	return (
		<div style={{width:"100%", display:"flex", marginTop:"50px"}}>
			<SideBar/>
			<div className="main-container center" onClick={() => auth.is_sidebar_collapsed ? void(0) : dispatch(toggleSidebarCollapsed())}>
				{ Util.functionTitle(translate.text.CASH_REMITTANCE) }
				<div className="function-sub-title">
				</div>

				<ButtonGroup className="button-group" minimal={false}>
					<Button
						text={translate.button.SAVE_AS_PDF}
						className="function-button"
						icon={<Icon icon="document" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => PDF.export(
								translate.worksheet.file_name.CASH_REMITTANCE,
								worksheet.header,
								worksheet.data_set,
								auth.active_company,
								"a3",
								"portrait",
								[3],
								[70, 90, 70, 70, 70, "*"]
							)
						}
					/>
					<Button
						text={translate.button.SAVE_AS_XLSX}
						className="function-button"
						icon={<Icon icon="th" className="orange"/>}
						disabled={auth.status === resources.status.LOADING}
						onClick={
							e => Excel.export(
								translate.worksheet.file_name.CASH_REMITTANCE,
								worksheet.header,
								worksheet.data_set,
								auth.active_company
							)
						}
					/>
					{
						worksheet.focused_cell &&
						<Button
							text={worksheet.num_frozen_columns === 0 ? translate.button.FREEZE_PANE : translate.button.UNFREEZE_PANE}
							className="function-button"
							icon={<Icon icon="panel-table" className="orange"/>}
							disabled={auth.status === resources.status.LOADING}
							onClick={() => handleFreezePaneButtonClickedEvent()}
						/>
					}
				</ButtonGroup>

				<Card className="function-card">
					<div className="row mb-5">
						<TextField
							className="col-sm-12 col-md-2 col-lg-2 mt-5"
							id="from_date"
							label={translate.placeholder.FROM_DATE}
							type="date"
							variant="outlined"
							size="small"
							defaultValue={from_date}
							onChange={(e) => setFromDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							className="col-sm-12 col-md-2 col-lg-2 mt-5"
							id="to_date"
							label={translate.placeholder.TO_DATE}
							type="date"
							variant="outlined"
							size="small"
							defaultValue={to_date}
							onChange={(e) => setToDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</Card>

				<CashRemittanceSpreadsheet
					enable_select={false}
					enable_open={false}
					enable_edit={false}
					from_date={from_date}
					to_date={to_date}
					worksheet={worksheet}
					setWorkSheet={setWorkSheet}
				/>

			</div>
		</div>
	);
}
